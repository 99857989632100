import { useEffect, useState } from 'react'
import IconButton from '~/components/IconButton'
import ReactSelect from 'react-select'
import { apiRequest } from '~/apiService'
import { Col, Row } from 'reactstrap'
import SlideInput from '~/components/SlideInput'

export default function ShowLink() {
  const [link, setLink] = useState({ longLink: '', shortLink: '' })
  const [leadFormOptions, setLeadFormOptions] = useState([{}])
  const [leadFormCurrent, setLeadFormCurrent] = useState({
    label: '   Carregando...',
    value: '',
  })
  const [campaignOptions, setCampaignOptions] = useState([{}])
  const [campaignCurrent, setCampaignCurrent] = useState({
    label: '   Carregando...',
    value: '',
  })
  const [loading, setLoading] = useState(false)
  const [expandedForm, setExpandedForm] = useState(false)

  useEffect(() => {
    getData()
  }, [])

  async function getData() {
    const [
      responseLinkRequest,
      responseLeadFormRequest,
      responseCampaignOptionsRequest,
    ] = await Promise.allSettled([
      apiRequest('get', '/customer/request-link', {}, setLoading),
      apiRequest('options', '/customer/leadForm', {}, setLoading),
      apiRequest('options', '/campaign', {}, setLoading),
    ])

    setLink(responseLinkRequest?.value?.data)
    setLeadFormOptions(responseLeadFormRequest?.value?.data)
    setCampaignOptions(
      [
        {
          label: 'Nenhum',
          value: '',
        },
      ].concat(responseCampaignOptionsRequest?.value?.data),
    )
    setLeadFormCurrent(responseLinkRequest?.value?.data?.leadFormDefault)
    setCampaignCurrent({ label: 'Nenhum', value: '' })
  }

  function copyTravelLink(link) {
    navigator.clipboard.writeText(link)
  }

  async function redirectToTravelLink(link) {
    var win = window.open(link, '_blank')
    win.localStorage.setItem('expandedForm', expandedForm)
    win.focus()
  }

  const increaseLeadFormLink = leadForm => {
    const fragmentedLink = link.shortLink.split('/')
    fragmentedLink.splice(5, 1, leadForm)

    setLeadFormCurrent(() =>
      leadFormOptions.find(leadForm => leadForm.value == leadForm),
    )

    setLink(oldState => ({ ...oldState, shortLink: fragmentedLink.join('/') }))
  }

  const increaseCampaignLink = campaign => {
    const fragmentedLink = link.shortLink.split('/')
    fragmentedLink.splice(6, 1, campaign)

    if (campaign == '') fragmentedLink.pop()

    setCampaignCurrent(() =>
      campaignOptions.find(campaign => campaign.value == campaign),
    )

    setLink(oldState => ({ ...oldState, shortLink: fragmentedLink.join('/') }))
  }

  const replaceRoute = () => {
    const fragmentedLink = link.shortLink.split('/')
    console.log(fragmentedLink)

    if (fragmentedLink[3] == 'request-form-short') {
      console.log('1', fragmentedLink)
      fragmentedLink.splice(3, 1, 'request-form-short-e')
      setLink(oldState => ({
        ...oldState,
        shortLink: fragmentedLink.join('/'),
      }))
      console.log(fragmentedLink.join('/'))
    } else {
      console.log('2', fragmentedLink)
      fragmentedLink.splice(3, 1, 'request-form-short')
      setLink(oldState => ({
        ...oldState,
        shortLink: fragmentedLink.join('/'),
      }))
    }

    setExpandedForm(oldState => !oldState)
  }

  return (
    <>
      <div
        style={{
          width: '100%',
          height: 'auto',
        }}
      >
        <b>Link para cadastro completo</b>
        <input
          style={{
            height: '38px',
            borderRadius: '5px',
            border: '1px solid #ccc',
            width: '90%',
            marginRight: '2%',
          }}
          type='text'
          label='Link para o Viajante'
          name={'link'}
          value={loading ? '   Carregando...' : link.longLink}
        />
        <IconButton
          iconOverride={'fas fa-copy'}
          tooltipOverride={'Copiar'}
          onClick={() => copyTravelLink(link.longLink)}
        />
        <IconButton
          iconOverride={'fas fa-external-link-alt'}
          tooltipOverride={'Link'}
          onClick={() => redirectToTravelLink(link.longLink)}
        />
      </div>

      <br />

      <div style={{ width: '100%', height: 'auto' }}>
        <b>Link para cadastro curto</b>
        <Row style={{ margin: '5px 0' }}>
          <Col sm={4} md={4} lg={4} xl={4} style={{ padding: '0' }}>
            <label style={{ fontWeight: '600' }}>Forma de contato</label>
            <ReactSelect
              options={leadFormOptions}
              name={'leadForm'}
              onChange={props => increaseLeadFormLink(props.value)}
              value={leadFormCurrent}
            />
          </Col>
          <Col sm={4} md={4} lg={4} xl={4} style={{ padding: '0 0 0 5px' }}>
            <label style={{ fontWeight: '600' }}>Campanha</label>
            <ReactSelect
              options={campaignOptions}
              name={'campaign'}
              value={campaignCurrent}
              onChange={props => increaseCampaignLink(props.value)}
            />
          </Col>
          <Col sm={4} md={4} lg={4} xl={4} style={{ padding: '0 0 0 5px' }}>
            <SlideInput
              name={'expandedForm'}
              label={'Formulário expandido'}
              value={expandedForm}
              onChange={() => replaceRoute()}
            />
          </Col>
        </Row>
        <input
          style={{
            height: '38px',
            borderRadius: '5px',
            border: '1px solid #ccc',
            width: '90%',
            marginRight: '2%',
          }}
          type='text'
          label='Link para o Viajante'
          name={'link'}
          value={loading ? '   Carregando...' : link.shortLink}
        />
        <IconButton
          iconOverride={'fas fa-copy'}
          tooltipOverride={'Copiar'}
          onClick={() => copyTravelLink(link.shortLink)}
        />
        <IconButton
          iconOverride={'fas fa-external-link-alt'}
          tooltipOverride={'Link'}
          onClick={() => redirectToTravelLink(link.shortLink)}
        />
      </div>
    </>
  )
}
