export const inputs = ddi => [
  {
    type: 'text',
    name: 'fullName',
    label: 'Nome completo',
    max: 40,
    required: true,
    sm: 12,
    md: 4,
    lg: 4,
    xl: 4,
  },
  {
    type: 'select',
    name: 'ddi',
    label: 'DDI',
    options: ddi,
    placeholder: 'Selecione...',
    required: true,
    sm: 12,
    md: 2,
    lg: 2,
    xl: 2,
  },
  {
    type: 'text',
    name: 'phone',
    label: 'Telefone',
    mask: '(99) 99999-9999',
    required: true,
    sm: 12,
    md: 3,
    lg: 3,
    xl: 3,
  },
  {
    type: 'email',
    name: 'email',
    label: 'E-mail',
    required: true,
    sm: 12,
    md: 3,
    lg: 3,
    xl: 3,
  },
]

export const inputsToExpand = [
  {
    type: 'text',
    name: 'cidadeDeEmbarque',
    label: 'Cidade de embarque',
    sm: 12,
    md: 2,
    lg: 4,
    xl: 4,
  },
  {
    type: 'number',
    name: 'quantidadeDePessoas',
    label: 'Número de pessoas',
    sm: 12,
    md: 2,
    lg: 2,
    xl: 2,
  },
  {
    type: 'select',
    name: 'acomodacao',
    label: 'Acomodação',
    options: [
      { label: 'Quádruplo', value: 'Quádruplo' },
      { label: 'Triplo', value: 'Triplo' },
      { label: 'Duplo', value: 'Duplo' },
      { label: 'Individual', value: 'Individual' },
    ],
    sm: 12,
    md: 2,
    lg: 2,
    xl: 2,
  },
  {
    type: 'select',
    name: 'atendimento',
    label: 'Atendimento de sua preferencia',
    options: [
      {
        label: 'Presencial em nossa loja',
        value: 'Presencial em nossa loja',
      },
      {
        label: 'Atendimento online virtual',
        value: 'Atendimento online virtual',
      },
      { label: 'Telefone', value: 'Telefone' },
      { label: 'WhatsApp', value: 'WhatsApp' },
    ],
    sm: 12,
    md: 2,
    lg: 4,
    xl: 4,
  },
]

export const inputCheck = [
  {
    type: 'check',
    name: 'confirmTermOfService',
    label: (
      <>
        Li e concordo com os termos e condições dispostos
        <a
          href='https://tripmee.com.br/lgpd.html#politica-privacidade'
          target='_black'
        >
          {' '}
          aqui.
        </a>
      </>
    ),
    required: true,
    sm: 12,
    md: 6,
    lg: 6,
    xl: 6,
  },
]
