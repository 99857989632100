import * as S from './styles'
import Timestamp from './Timestamp'
import { useCallback, useState } from 'react'
import PropTypes from 'prop-types'
import MediaPreview from './MediaPreview'
import ConnectedMessage from './ConnectedMessage'

export default function DefaultMessage({ conversation, toMe, getMedia }) {
  const [connect, setConnect] = useState(
    false,
    // false ||
    //   (conversation && conversation?.type === 'image') ||
    //   (conversation && conversation?.type === 'sticker') ||
    //   (conversation && conversation?.type === 'audio'),
  )

  const setUserMessageTextWithUserName = useCallback((text, inline = false) => {
    let userName = ''

    const matchedUserName = text?.match(/\*(.*?)\*/)

    if (matchedUserName?.length > 0) {
      userName = matchedUserName[1]
    }

    const newText = text.replace(/^[\s\S]*?\n/, '')

    return (
      <>
        {userName && (
          <span>
            <b>{userName}:&nbsp;</b>

            {!inline && <br></br>}

            {newText}
          </span>
        )}

        {!userName && <>{text}</>}
      </>
    )
  })

  return (
    <S.DefaultMessageContainer toMe={toMe}>
      <S.DefaultMessageContent toMe={toMe} type={conversation?.type}>
        {conversation?.phoneNumber?.search('group') > 0 && (
          <>
            <b>Grupo</b> <br />
          </>
        )}

        {conversation?.type === 'text' &&
          setUserMessageTextWithUserName(conversation?.text?.body)}

        {!connect && (
          <>
            {(conversation?.type === 'document' ||
              conversation?.type === 'image' ||
              conversation?.type === 'sticker' ||
              conversation?.type === 'audio' ||
              conversation?.type === 'video') && (
              <MediaPreview
                downloadable
                title={conversation?.document?.fileName}
                onClick={() => setConnect(true)}
                type={conversation.type}
              />
            )}
          </>
        )}

        {connect && (
          <ConnectedMessage message={conversation} getMedia={getMedia} />
        )}

        <Timestamp message={conversation} toMe={toMe} />
      </S.DefaultMessageContent>
    </S.DefaultMessageContainer>
  )
}

DefaultMessage.propTypes = {
  conversation: {
    photo: PropTypes.string,
    name: PropTypes.string,
    phoneNumber: PropTypes.string,
    lastMessage: PropTypes.string,
    read: PropTypes.bool,
    delivered: PropTypes.bool,
    sent: PropTypes.bool,
  },
  toMe: PropTypes.bool,
}
