import { produce } from 'immer'
import {
  ADD_MEDIA,
  CLEAR_ALL_MESSAGE,
  SET_MESSAGE,
  SET_LAST_MESSAGE,
} from '../actions/messageAction'

const initialState = {
  messages: [
    // {
    //   type: '',
    //   timestamp: '',
    //   content: '',
    //   from: '',
    //   read: false,
    //   delivered: false,
    //   sent: false,
    // },
  ],
}

const messageReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_MESSAGE: {
      return produce(state, draftState => {
        draftState.messages = action.data // Immer handles immutability for you
      })
    }
    case SET_LAST_MESSAGE: {
      return {
        ...state,
        lastMessageReceived: action.data,
      }
    }
    case ADD_MEDIA: {
      let messages = [...state.messages]
      const index = messages.findIndex(m => m._id === action.id)
      messages[index] = {
        ...messages[index],
        media: {
          ...messages[index].media,
          content: action.data,
        },
      }
      return {
        ...state,
        messages: messages,
      }
    }
    case CLEAR_ALL_MESSAGE:
      return {
        ...state,
        messages: [],
      }
    default:
      return state
  }
}

export default messageReducer
