import { getDateFormated } from '~/functions/Date'
import {
  Container,
  Div,
  Image,
  TopTitle,
  DivEntity,
  TitleActivity,
  Box,
  EncapsuleBoxTitle,
  DivActivity,
  Table,
  DivGlobal,
  Button,
  DivActivityDesc,
  AgencyImage,
  TotalValueLine,
  Register,
  ImportantInformation,
  AgentInformation,
  Hr,
  ImportantInformationObservation,
  HrColor,
} from './styles'
import Footer from '../../Footer'
import moment from 'moment'
import { FormatValueBRL, FormatValueUSD } from '~/functions/FormatValue'
import { getFirstWords } from '~/functions/String'
import DivForHTML from '~/components/DivForHTML'
import { P } from '~/pages/ApplicationForm/Survey/Form/style'
import IconButton from '~/components/IconButton'
import { between } from 'polished'
import Divisor from '~/components/Divisor'
import { useEffect } from 'react'

export default function Preview({
  agency,
  title = '',
  dateInitial,
  dateFinal,
  image = '',
  image2 = '',
  description = '',
  primaryColor = '',
  secondaryColor = '',
  observation = '',
  notIncluded = '',
  included = '',
  alter = '',
  cancel = '',
  reservation = '',
  countryExi = '',
  allValues,
  showTotalValue,
  dolar,
  fullValue,
  id,
  createdAt,
  proposalFlights = [],
  proposalNauticals = [],
  proposalTrains = [],
  proposalTransfers = [],
  proposalHotels = [],
  proposalActivitys = [],
  proposalInsurances = [],
  proposalOtherServices = [],
  proposalDestinys = [],
  proposalTickets = [],
  proposalVehicleRentals = [],
  totalValue = null,
  agentName,
  agentPhone,
  agentEmail,
  isMobile,
  handlePrint,
  handleDownload,
  agencyPhone,
}) {
  const sumOfActivityValues = () => {
    const sum = proposalActivitys.reduce((acc, activity) => {
      acc += Number(activity.value)
      return acc
    }, 0)
    const sumToFixed = sum

    const result = FormatValueBRL(sumToFixed)

    return result
  }

  const sumOfActivityValuesUSD = () => {
    const sum = proposalActivitys.reduce((acc, activity) => {
      acc += Number(activity.value)
      return acc
    }, 0)
    const sumToFixed = sum

    const result = FormatValueUSD(sumToFixed)

    return result
  }

  const formatterValue = value => {
    const valueToString = String(value)

    const includesDot =
      valueToString.includes('.') || valueToString.includes(',')

    const valueFormated = includesDot
      ? `${valueToString}`
      : `${valueToString}.00`

    return valueFormated
  }

  const dataProcessing = date => {
    const data = getDateFormated(date, 'DD/MM/YYYY HH:mm')

    if (data.includes('00:00')) {
      return getDateFormated(date, 'DD/MM/YYYY')
    } else {
      return getDateFormated(date, 'DD/MM/YYYY HH:mm')
    }
  }

  const changeNullForHyphen = value => {
    const transform = value == null ? '-' : value

    return transform
  }

  const changeNullForDate = value => {
    const transform = value == null ? '-' : dataProcessing(value)

    const result = transform == '01/01/0001' ? '-' : transform

    return result
  }

  const changeNullForValue = value => {
    const transform = value == null ? '-' : formatterValue(value)

    return transform
  }

  console.log(reservation)

  return (
    <div>
      <DivGlobal>
        <Container>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <AgencyImage
              src={`data:image/*;base64,${agency}`}
              alt='Logo da agência'
            />
            <div>
              <AgentInformation>N° da proposta: {id}</AgentInformation>
              <AgentInformation>Criada em: {createdAt}</AgentInformation>
              <AgentInformation>Consultor: {agentName}</AgentInformation>
              <AgentInformation>Email: {agentEmail}</AgentInformation>
              {agencyPhone && (
                <AgentInformation>Telefone: {agencyPhone}</AgentInformation>
              )}
            </div>
          </div>
          <Div>
            <TopTitle primaryColor={primaryColor}>
              <p
                style={{
                  color: '#b79974',
                  fontFamily: 'Joanna Sans Nova Regular',
                  fontSize: '25px',
                }}
              >
                {title}
              </p>
              {/* <h6>
                Período da viagem: {dateInitial} a {dateFinal}
              </h6> */}
            </TopTitle>

            <Button
              primaryColor={primaryColor}
              type='button'
              onClick={() => handlePrint()}
            >
              Baixar pdf
            </Button>
          </Div>

          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
            }}
          ></div>

          {image && image2 ? (
            <div style={{ display: 'flex', gap: '0.5cm' }}>
              <Image
                src={`data:image/*;base64,${image}`}
                alt='Logo da agência'
              />
              <Image
                src={`data:image/*;base64,${image2}`}
                alt='Logo da agência'
              />
            </div>
          ) : image ? (
            <div style={{ display: 'flex', gap: '0.5cm' }}>
              <Image
                src={`data:image/*;base64,${image}`}
                alt='Logo da agência'
              />
            </div>
          ) : (
            image2 && (
              <div style={{ display: 'flex', gap: '0.5cm' }}>
                <Image
                  src={`data:image/*;base64,${image2}`}
                  alt='Logo da agência'
                />
              </div>
            )
          )}

          {description != '<p><br></p>' && (
            <DivEntity primaryColor={primaryColor}>
              <EncapsuleBoxTitle>
                <p>
                  <i className='fas fa-suitcase-rolling' /> SOBRE A VIAGEM
                  <Hr></Hr>
                </p>
              </EncapsuleBoxTitle>
              <ImportantInformation>
                <DivForHTML
                  text={
                    `<a style="white-space: pre-line">` + description + `</a>`
                  }
                />
              </ImportantInformation>
            </DivEntity>
          )}

          {proposalDestinys.length >= 1 && (
            <DivEntity primaryColor={primaryColor} className='no-break'>
              <EncapsuleBoxTitle>
                <p>
                  <i className='fas fa-map-marker-alt' /> DESTINOS
                  <Hr></Hr>
                </p>
              </EncapsuleBoxTitle>
              <Table>
                <tr>
                  <th>N°</th>
                  <th>NOME</th>
                  <th>DATA INICIAL</th>
                  <th>DATA FINAL</th>
                </tr>
                {proposalDestinys.map((e, i) => (
                  <tr key={i}>
                    <td>{i + 1}</td>
                    <td>{changeNullForHyphen(e.name)}</td>
                    <td>{changeNullForDate(e.dateStart)}</td>
                    <td>{changeNullForDate(e.dateEnd)}</td>
                  </tr>
                ))}
              </Table>
            </DivEntity>
          )}

          {proposalFlights?.some(x => x.proposalFlightExcerpts.length >= 1) && (
            <>
              {proposalFlights.map((flight, i) => (
                <>
                  <DivEntity primaryColor={primaryColor} className='no-break'>
                    <EncapsuleBoxTitle>
                      <p>
                        <i className='fas fa-plane-departure' /> ITINERÁRIO
                        AÉREO OPÇÃO {i + 1}
                        <Hr></Hr>
                      </p>
                    </EncapsuleBoxTitle>
                    <div
                      style={{ overflowX: `${isMobile ? 'scroll' : 'none'}` }}
                    >
                      <Table key={i}>
                        <thead>
                          <tr>
                            <th>CIA AEREA</th>
                            <th>ORIGEM</th>
                            <th>DESTINO</th>
                            <th>DATA SAÍDA</th>
                            <th>DATA CHEGADA</th>
                          </tr>
                        </thead>
                        {flight.proposalFlightExcerpts?.map((excerpt, i2) => (
                          // eslint-disable-next-line react/jsx-key
                          <tbody>
                            <tr>
                              <td>{changeNullForHyphen(excerpt.cia)}</td>
                              <td>{changeNullForHyphen(excerpt.origin)}</td>
                              <td>{changeNullForHyphen(excerpt.destiny)}</td>
                              <td>{changeNullForDate(excerpt.date)}</td>
                              <td>{changeNullForDate(excerpt.dateArrival)}</td>
                            </tr>
                          </tbody>
                        ))}
                      </Table>
                    </div>
                  </DivEntity>
                </>
              ))}
            </>
          )}

          {proposalFlights?.map((flight, i) => (
            // eslint-disable-next-line react/jsx-key
            <DivEntity primaryColor={primaryColor} className='no-break'>
              <EncapsuleBoxTitle>
                <p>
                  <i className='fas fa-plane-departure' /> PASSAGENS - OPÇÃO{' '}
                  {i + 1}
                  <Hr></Hr>
                </p>
              </EncapsuleBoxTitle>
              <div style={{ overflowX: `${isMobile ? 'scroll' : 'none'}` }}>
                <Table key={i}>
                  <thead>
                    <tr>
                      <th>N°</th>
                      <th>PASSAGEIROS</th>
                      <th>DESCRIÇÃO DOS PASSAGEIROS</th>
                      <th>FORMA DE PAGAMENTO</th>
                      <th>BAGAGEM</th>
                      {allValues != false && <th>VALOR</th>}
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>{i + 1}</td>
                      <td>{changeNullForHyphen(flight.passengers)}</td>
                      <td>
                        {changeNullForHyphen(flight.passengersDescription)}
                      </td>
                      <td>{changeNullForHyphen(flight.descriptionPayment)}</td>
                      <td>
                        {changeNullForHyphen(flight.baggage ? 'SIM' : 'NÃO')}
                      </td>
                      {allValues != false && dolar && (
                        <td>{FormatValueUSD(flight.value)}</td>
                      )}
                      {allValues != false && !dolar && (
                        <td>{FormatValueBRL(flight.value)}</td>
                      )}
                    </tr>
                  </tbody>
                </Table>
                <br></br>
                {flight?.proposalFlightFile?.length >= 1 && (
                  <>
                    <ImportantInformation>
                      <b>
                        {flight?.proposalFlightFile?.length > 1
                          ? 'Imagens '
                          : 'Imagem '}
                        do Aéreo {i + 1}:
                      </b>
                    </ImportantInformation>
                    <div
                      style={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                      }}
                    >
                      {flight?.proposalFlightFile?.map((file, iF) => (
                        <img
                          key={iF}
                          style={{
                            width: '49.5%',
                            height: '400px',
                          }}
                          src={`data:image/*;base64,${file?.file}`}
                          alt='Imagem do aéreo'
                        />
                      ))}
                    </div>
                  </>
                )}
                {flight.importantInformation && (
                  <div key={i}>
                    <ImportantInformation>
                      <b>Informações importantes do Aéreo {i + 1}: </b>
                      <a
                        style={{
                          whiteSpace: 'pre-line',
                        }}
                      >
                        {flight.importantInformation}
                      </a>
                    </ImportantInformation>
                  </div>
                )}
              </div>
            </DivEntity>
          ))}

          {proposalHotels.length >= 1 && (
            <DivEntity primaryColor={primaryColor} className='no-break'>
              <EncapsuleBoxTitle>
                <p>
                  <i className='fas fa-hotel' /> HOSPEDAGEM
                  <Hr></Hr>
                </p>
              </EncapsuleBoxTitle>
              <div style={{ overflowX: `${isMobile ? 'scroll' : 'none'}` }}>
                <Table primaryColor={'#A4A4A4'}>
                  <tr>
                    <th>N°</th>
                    <th>NOME</th>
                    <th>ENDEREÇO</th>
                    <th>QUARTO</th>
                    <th>REGIME ALIMENTAR</th>
                    <th>CHECKIN</th>
                    <th>CHECKOUT</th>
                    {allValues != false && <th>VALOR</th>}
                  </tr>
                  {proposalHotels.map((e, i) => (
                    <tr key={i}>
                      <td>{i + 1}</td>
                      <td>{changeNullForHyphen(e.name)}</td>
                      <td>{changeNullForHyphen(e.fullAddress)}</td>
                      <td>{changeNullForHyphen(e.roomType)}</td>
                      <td>{changeNullForHyphen(e.diet)}</td>
                      <td>{changeNullForDate(e.checkIn)}</td>
                      <td>{changeNullForDate(e.checkOut)}</td>
                      {allValues != false && dolar && (
                        <td>{FormatValueUSD(e.value)}</td>
                      )}
                      {allValues != false && !dolar && (
                        <td>{FormatValueBRL(e.value)}</td>
                      )}
                    </tr>
                  ))}
                </Table>
                <br></br>
                {proposalHotels?.map((hotel, iH) => (
                  <div key={iH}>
                    {hotel?.proposalHotelFile?.length >= 1 && (
                      <>
                        <ImportantInformation>
                          <b>
                            {hotel?.proposalHotelFile?.length > 1
                              ? 'Imagens '
                              : 'Imagem '}
                            do Hotel {iH + 1}:
                          </b>
                        </ImportantInformation>
                        <div
                          style={{
                            width: '100%',
                            display: 'flex',
                            justifyContent: 'center',
                          }}
                        >
                          {hotel?.proposalHotelFile?.map((file, iF) => (
                            <img
                              key={iF}
                              style={{
                                width: '49.5%',
                                height: '400px',
                              }}
                              src={`data:image/*;base64,${file?.file}`}
                              alt='Imagem do hotel'
                            />
                          ))}
                        </div>
                      </>
                    )}
                    {hotel.importantInformation && (
                      <ImportantInformation>
                        <b>Informações importantes do Hotel {iH + 1}: </b>
                        <a style={{ fontSize: '15px', whiteSpace: 'pre-line' }}>
                          {hotel.importantInformation}
                        </a>
                      </ImportantInformation>
                    )}

                    {(hotel?.proposalHotelFile?.length >= 1 ||
                      hotel.importantInformation) && <Divisor border={true} />}
                  </div>
                ))}
              </div>
            </DivEntity>
          )}

          {proposalOtherServices.length >= 1 && (
            <DivEntity primaryColor={primaryColor} className='no-break'>
              <EncapsuleBoxTitle>
                <p>
                  <i className='fas fa-shopping-bag' /> OUTROS SERVIÇOS
                  <Hr></Hr>
                </p>
              </EncapsuleBoxTitle>
              <div style={{ overflowX: `${isMobile ? 'scroll' : 'none'}` }}>
                <Table primaryColor={'#A4A4A4'}>
                  <tr>
                    <th>N°</th>
                    <th>NOME</th>
                    <th>CIDADE</th>
                    <th>INÍCIO</th>
                    <th>FIM</th>
                    {allValues != false && <th>VALOR</th>}
                  </tr>
                  {proposalOtherServices.map((e, i) => (
                    <tr key={i}>
                      <td>{i + 1}</td>
                      <td>{changeNullForHyphen(e.title)}</td>
                      <td>{changeNullForHyphen(e.city)}</td>
                      <td>{changeNullForDate(e.start)}</td>
                      <td>{changeNullForDate(e.end)}</td>
                      {allValues != false && dolar && (
                        <td>{FormatValueUSD(e.value)}</td>
                      )}
                      {allValues != false && !dolar && (
                        <td>{FormatValueBRL(e.value)}</td>
                      )}
                    </tr>
                  ))}
                </Table>
                <br></br>
                {proposalOtherServices.map((e, i) => (
                  <div key={i}>
                    {e.desc && (
                      <ImportantInformation>
                        <DivForHTML
                          text={
                            `<b>Descrição do serviço ${
                              i + 1
                            }: </b><a style="white-space: pre-line">` +
                            getFirstWords(e.desc, 2000) +
                            `</a>`
                          }
                          // {description}
                        />
                        {/* <a style={{ color: 'black !important' }}></a> */}
                        {/* {e.desc} */}
                      </ImportantInformation>
                    )}
                    <br />
                  </div>
                ))}
              </div>
            </DivEntity>
          )}

          {proposalActivitys.length >= 1 && (
            <div>
              <DivEntity primaryColor={primaryColor} className='no-break'>
                <EncapsuleBoxTitle>
                  <p>
                    <i className='far fa-calendar-alt' /> ITINERÁRIO TERRESTRE
                    <Hr></Hr>
                  </p>
                </EncapsuleBoxTitle>
                {proposalActivitys.map((e, i) => (
                  <DivActivity key={i}>
                    <EncapsuleBoxTitle>
                      <TitleActivity>
                        {i + 1}° Dia
                        {moment(e.start).format('LL') == 'Data inválida'
                          ? ''
                          : ' - ' + moment(e.start).format('LL')}
                      </TitleActivity>
                      <br></br>
                      <br></br>
                      <br></br>
                    </EncapsuleBoxTitle>
                    <DivActivityDesc>
                      {e.startAddress} -{' '}
                      {e.desc
                        ?.replace(/<[A-z]*>/g, '')
                        .replace(/<\/[A-z]*>/g, '')}
                      <HrColor></HrColor>
                    </DivActivityDesc>
                  </DivActivity>
                ))}
              </DivEntity>
              {allValues != false && (
                <DivEntity primaryColor={primaryColor} className='no-break'>
                  <EncapsuleBoxTitle>
                    <p>
                      <i className='fas fa-credit-card' /> VALORES DAS
                      ATIVIDADES DO ROTEIRO
                      <Hr></Hr>
                    </p>
                  </EncapsuleBoxTitle>
                  <Table primaryColor={'#A4A4A4'}>
                    <tr>
                      <th>Serviço</th>
                      {allValues != false && <th>VALOR</th>}
                    </tr>
                    {proposalActivitys.map((e, i) => (
                      <tr key={i}>
                        <td>{e.startAddress}</td>
                        {allValues != false && e.value == null && !dolar && (
                          <td>R$ 0.00</td>
                        )}
                        {allValues != false && e.value == null && dolar && (
                          <td>$ 0.00</td>
                        )}
                        {allValues != false && e.value != null && dolar && (
                          <td>{FormatValueUSD(e.value)}</td>
                        )}
                        {allValues != false && e.value != null && !dolar && (
                          <td>{FormatValueBRL(e.value)}</td>
                        )}
                      </tr>
                    ))}
                    {allValues != false && dolar && (
                      <TotalValueLine>
                        <td>Total</td>
                        <td>{FormatValueUSD(sumOfActivityValuesUSD())}</td>
                      </TotalValueLine>
                    )}
                    {allValues != false && !dolar && (
                      <TotalValueLine>
                        <td>Total</td>
                        <td>{FormatValueBRL(sumOfActivityValues())}</td>
                      </TotalValueLine>
                    )}
                  </Table>
                </DivEntity>
              )}
            </div>
          )}

          {proposalNauticals.length >= 1 && (
            // eslint-disable-next-line react/jsx-key
            <DivEntity primaryColor={primaryColor} className='no-break'>
              <EncapsuleBoxTitle>
                <p>
                  <i className='fas fa-anchor' /> NÁUTICO
                  <Hr></Hr>
                </p>
              </EncapsuleBoxTitle>
              <div style={{ overflowX: `${isMobile ? 'scroll' : 'none'}` }}>
                <Table primaryColor={'#A4A4A4'}>
                  <tr>
                    <th>N°</th>
                    <th>CIA</th>
                    <th>TIPO</th>
                    <th>CABINE</th>
                    <th>ORIGEM</th>
                    <th>DESTINO</th>
                    <th>PARTIDA</th>
                    <th>CHEGADA</th>
                    {allValues != false && <th>VALOR</th>}
                  </tr>
                  {proposalNauticals.map((e, i) => (
                    <tr key={i}>
                      <td>{i + 1}</td>
                      <td>{changeNullForHyphen(e.cia)}</td>
                      <td>{changeNullForHyphen(e.type)}</td>
                      <td>{changeNullForHyphen(e.cabinType)}</td>
                      <td>{changeNullForHyphen(e.origin)}</td>
                      <td>{changeNullForHyphen(e.destiny)}</td>
                      <td>{changeNullForDate(e.departure)}</td>
                      <td>{changeNullForDate(e.arrival)}</td>
                      {allValues != false && dolar && (
                        <td>{FormatValueUSD(e.value)}</td>
                      )}
                      {allValues != false && !dolar && (
                        <td>{FormatValueBRL(e.value)}</td>
                      )}
                    </tr>
                  ))}
                </Table>
                <br></br>
                {proposalNauticals?.map((hotel, iH) => (
                  <div key={iH}>
                    {hotel?.proposalNauticalFiles?.length >= 1 && (
                      <>
                        <ImportantInformation>
                          <b>
                            {hotel?.proposalNauticalFiles?.length > 1
                              ? 'Imagens '
                              : 'Imagem '}
                            do Hotel {iH + 1}:
                          </b>
                        </ImportantInformation>
                        <div
                          style={{
                            width: '100%',
                            display: 'flex',
                            justifyContent: 'center',
                          }}
                        >
                          {hotel?.proposalNauticalFiles?.map((file, iF) => (
                            <img
                              key={iF}
                              style={{
                                width: '49.5%',
                                height: '300px',
                              }}
                              src={`data:image/*;base64,${file?.file}`}
                              alt='Imagem do hotel'
                            />
                          ))}
                        </div>
                      </>
                    )}
                    {hotel.importantInformation && (
                      <ImportantInformation>
                        <b>Informações importantes do Hotel {iH + 1}: </b>
                        <a style={{ fontSize: '15px' }}>
                          {hotel.importantInformation}
                        </a>
                      </ImportantInformation>
                    )}

                    {(hotel.proposalNauticalFiles.length >= 1 ||
                      hotel.importantInformation) && <Divisor border={true} />}
                  </div>
                ))}
              </div>
            </DivEntity>
          )}

          {proposalTrains.length >= 1 && (
            // eslint-disable-next-line react/jsx-key
            <DivEntity primaryColor={primaryColor} className='no-break'>
              <EncapsuleBoxTitle>
                <p>
                  <i className='fas fa-train' /> TREM
                  <Hr></Hr>
                </p>
              </EncapsuleBoxTitle>
              <div style={{ overflowX: `${isMobile ? 'scroll' : 'none'}` }}>
                <Table primaryColor={'#A4A4A4'}>
                  <tr>
                    <th>N°</th>
                    <th>CIA</th>
                    <th>ORIGEM</th>
                    {/* <th
                      style={{
                        borderTop: '1px solid transparent',
                        borderBottom: '1px solid transparent',
                        borderLeft: '1px solid transparent',
                        borderRight: '1px solid #CBCBCB',
                      }}
                    >
                      Estação de origem
                    </th> */}
                    <th>DESTINO</th>
                    {/* <th
                      style={{
                        borderTop: '1px solid transparent',
                        borderBottom: '1px solid transparent',
                        borderLeft: '1px solid transparent',
                        borderRight: '1px solid #CBCBCB',
                      }}
                    >
                      Estação de destino
                    </th> */}
                    <th>PARTIDA</th>
                    <th>CHEGADA</th>
                    {allValues != false && <th>VALOR</th>}
                  </tr>
                  {proposalTrains.map((e, i) => (
                    <tr key={i}>
                      <td>{i + 1}</td>
                      <td>{changeNullForHyphen(e.cia)}</td>
                      <td>{changeNullForHyphen(e.origin)}</td>
                      {/* <td
                        style={{
                          borderTop: '1px solid transparent',
                          borderBottom: '1px solid transparent',
                          borderLeft: '1px solid transparent',
                          borderRight: '1px solid #CBCBCB',
                        }}
                      >
                        {changeNullForHyphen(e.stationOrigin)}
                      </td> */}
                      <td>{changeNullForHyphen(e.destiny)}</td>
                      {/* <td
                        style={{
                          borderTop: '1px solid transparent',
                          borderBottom: '1px solid transparent',
                          borderLeft: '1px solid transparent',
                          borderRight: '1px solid #CBCBCB',
                        }}
                      >
                        {changeNullForHyphen(e.stationDestiny)}
                      </td> */}
                      <td>{changeNullForDate(e.departure)}</td>
                      <td>{changeNullForDate(e.arrival)}</td>
                      {allValues != false && dolar && (
                        <td>{FormatValueUSD(e.value)}</td>
                      )}
                      {allValues != false && !dolar && (
                        <td>{FormatValueBRL(e.value)}</td>
                      )}
                    </tr>
                  ))}
                </Table>
                <br></br>
                {proposalTrains.map((e, i) => (
                  <div key={i}>
                    {e.importantInformation && (
                      <ImportantInformation>
                        <b>Informações importantes do trem {i + 1}: </b>
                        <a style={{ fontSize: '16px', whiteSpace: 'pre-line' }}>
                          {e.importantInformation}
                        </a>
                      </ImportantInformation>
                    )}
                  </div>
                ))}
              </div>
            </DivEntity>
          )}

          {proposalTransfers.length >= 1 && (
            // eslint-disable-next-line react/jsx-key
            <DivEntity primaryColor={primaryColor} className='no-break'>
              <EncapsuleBoxTitle>
                <p>
                  <i className='fas fa-car'></i> TRANSFER
                  <Hr></Hr>
                </p>
              </EncapsuleBoxTitle>
              <div style={{ overflowX: `${isMobile ? 'scroll' : 'none'}` }}>
                <Table primaryColor={'#A4A4A4'}>
                  <tr>
                    <th>N°</th>
                    <th>ORIGEM</th>
                    <th>ENDEREÇO DE ORIGEM</th>
                    <th>DESTINO</th>
                    <th>ENDEREÇO DE DESTINO</th>
                    <th>CHECKIN</th>
                    <th>CHECKOUT</th>
                    {allValues != false && <th>VALOR</th>}
                  </tr>
                  {proposalTransfers.map((e, i) => (
                    <tr key={i}>
                      <td>{i + 1}</td>
                      <td>{changeNullForHyphen(e.origin)}</td>
                      <td>{changeNullForHyphen(e.originAddress)}</td>
                      <td>{changeNullForHyphen(e.destiny)}</td>
                      <td>{changeNullForHyphen(e.destinyAddress)}</td>
                      <td>{changeNullForDate(e.checkIn)}</td>
                      <td>{changeNullForDate(e.checkOut)}</td>
                      {allValues != false && dolar && (
                        <td>{FormatValueUSD(e.value)}</td>
                      )}
                      {allValues != false && !dolar && (
                        <td>{FormatValueBRL(e.value)}</td>
                      )}
                    </tr>
                  ))}
                </Table>
                <br></br>
                {proposalTransfers.map((e, i) => (
                  <div key={i}>
                    {e.importantInformation && (
                      <ImportantInformation>
                        <b>Informações importantes do transfer {i + 1}: </b>
                        <a style={{ fontSize: '16px', whiteSpace: 'pre-line' }}>
                          {e.importantInformation}
                        </a>
                      </ImportantInformation>
                    )}
                    <br />
                  </div>
                ))}
              </div>
            </DivEntity>
          )}

          {proposalInsurances.length >= 1 && (
            <DivEntity primaryColor={primaryColor} className='no-break'>
              <EncapsuleBoxTitle>
                <p>
                  <i className='fas fa-shield-alt' /> SEGURO
                  <Hr></Hr>
                </p>
              </EncapsuleBoxTitle>
              <div style={{ overflowX: `${isMobile ? 'scroll' : 'none'}` }}>
                <Table primaryColor={'#A4A4A4'}>
                  <tr>
                    <th>N°</th>
                    <th>TIPO DE COBERTURA</th>
                    <th>FORMA DE PAGAMENTO</th>
                    <th>INÍCIO</th>
                    <th>FIM</th>
                    {allValues != false && <th>VALOR</th>}
                  </tr>
                  {proposalInsurances.map((e, i) => (
                    <tr key={i}>
                      <td>{i + 1}</td>
                      <td>{changeNullForHyphen(e.typeCoverage)}</td>
                      <td>{changeNullForHyphen(e.descriptionPayment)}</td>
                      <td>{changeNullForDate(e.timeStart)}</td>
                      <td>{changeNullForDate(e.timeEnd)}</td>
                      {allValues != false && dolar && (
                        <td>{FormatValueUSD(e.value)}</td>
                      )}
                      {allValues != false && !dolar && (
                        <td>{FormatValueBRL(e.value)}</td>
                      )}
                    </tr>
                  ))}
                </Table>
                {proposalInsurances.map((e, i) => (
                  <div key={i}>
                    {e.description && (
                      <ImportantInformation>
                        <b>Informações importantes do seguro {i + 1}: </b>
                        <a
                          style={{
                            fontSize: '16px',
                            whiteSpace: 'pre-line',
                          }}
                        >
                          {e.description}
                        </a>
                      </ImportantInformation>
                    )}
                    <br />
                  </div>
                ))}
              </div>
            </DivEntity>
          )}

          {proposalVehicleRentals.length >= 1 && (
            <DivEntity primaryColor={primaryColor} className='no-break'>
              <EncapsuleBoxTitle>
                <p>
                  <i className='fas fa-car-side' /> LOCAÇÕES VEICULARES
                  <Hr></Hr>
                </p>
              </EncapsuleBoxTitle>
              <div style={{ overflowX: `${isMobile ? 'scroll' : 'none'}` }}>
                <Table primaryColor={'#A4A4A4'}>
                  <tr>
                    <th>N°</th>
                    <th>LOCADORA</th>
                    <th>TIPO</th>
                    <th>ENDEREÇO DE LOCAÇÃO</th>
                    <th>ENDEREÇO DE DEVOLUÇÃO</th>
                    <th>DATA DA LOCAÇÃO</th>
                    <th>DATA DA DEVOLUÇÃO</th>
                    {allValues != false && <th>VALOR</th>}
                  </tr>
                  {proposalVehicleRentals.map((e, i) => (
                    <tr key={i}>
                      <td>{i + 1}</td>
                      <td>{changeNullForHyphen(e.provider)}</td>
                      <td>{changeNullForHyphen(e.type)}</td>
                      <td>{changeNullForHyphen(e.originAddress)}</td>
                      <td>{changeNullForHyphen(e.destinyAddress)}</td>
                      <td>{changeNullForDate(e.checkIn)}</td>
                      <td>{changeNullForDate(e.checkOut)}</td>
                      {allValues != false && dolar && (
                        <td>{FormatValueUSD(e.value)}</td>
                      )}
                      {allValues != false && !dolar && (
                        <td>{FormatValueBRL(e.value)}</td>
                      )}
                    </tr>
                  ))}
                </Table>
                <br></br>
                {proposalVehicleRentals.map((e, i) => (
                  <div key={i}>
                    {e.importantInformation && (
                      <ImportantInformation>
                        <b>
                          Informações importantes da locação veicular {i + 1}:{' '}
                        </b>
                        <a
                          style={{
                            fontSize: '16px',
                            whiteSpace: 'pre-line',
                          }}
                        >
                          {e.importantInformation}
                        </a>
                      </ImportantInformation>
                    )}
                    <br />
                  </div>
                ))}
              </div>
            </DivEntity>
          )}

          {proposalTickets.length >= 1 && (
            <DivEntity primaryColor={primaryColor} className='no-break'>
              <EncapsuleBoxTitle>
                <p>
                  <i className='fas fa-ticket-alt' /> INGRESSO
                  <Hr></Hr>
                </p>
              </EncapsuleBoxTitle>
              <br></br>
              <div style={{ overflowX: `${isMobile ? 'scroll' : 'none'}` }}>
                <Table primaryColor={'#A4A4A4'}>
                  <tr>
                    <th>N°</th>
                    <th>TÍTULO</th>
                    <th>INÍCIO</th>
                    <th>FIM</th>
                    {allValues != false && <th>VALOR</th>}
                  </tr>
                  {proposalTickets.map((e, i) => (
                    <tr key={i}>
                      <td>{i + 1}</td>
                      <td>{changeNullForHyphen(e.title)}</td>
                      <td>{changeNullForDate(e.start)}</td>
                      <td>{changeNullForDate(e.end)}</td>
                      {allValues != false && dolar && (
                        <td>{FormatValueUSD(e.value)}</td>
                      )}
                      {allValues != false && !dolar && (
                        <td>{FormatValueBRL(e.value)}</td>
                      )}
                    </tr>
                  ))}
                </Table>
                <br></br>
                {proposalTickets.map((e, i) => (
                  <div key={i}>
                    {e.desc && (
                      <ImportantInformation>
                        <b>Descrição do ticket {i + 1}: </b>
                        <a
                          style={{
                            fontSize: '16px',
                            whiteSpace: 'pre-line',
                          }}
                        >
                          {e.desc}
                        </a>
                      </ImportantInformation>
                    )}
                    <br />
                  </div>
                ))}
              </div>
            </DivEntity>
          )}

          {included != '<p><br></p>' && included != '' && (
            <DivEntity primaryColor={primaryColor} className='no-break'>
              <EncapsuleBoxTitle>
                <p>
                  <i className='fas fa-plus-circle' /> NOSSO ROTEIRO INCLUI
                  <Hr></Hr>
                </p>
              </EncapsuleBoxTitle>
              <b style={{ fontSize: '14px', color: 'black' }}>
                <ImportantInformationObservation>
                  <DivForHTML text={included} />
                </ImportantInformationObservation>
              </b>
            </DivEntity>
          )}

          {notIncluded != '<p><br></p>' && notIncluded != '' && (
            <DivEntity primaryColor={primaryColor} className='no-break'>
              <EncapsuleBoxTitle>
                <p>
                  <i className='fas fa-minus-circle' /> NOSSO ROTEIRO NÃO INCLUI
                  <Hr></Hr>
                </p>
              </EncapsuleBoxTitle>
              <b style={{ fontSize: '14px', color: 'black' }}>
                <ImportantInformationObservation>
                  <DivForHTML
                    text={getFirstWords(notIncluded, 2000)}
                    // {description}
                  />
                </ImportantInformationObservation>
              </b>
            </DivEntity>
          )}

          {alter != '<p><br></p>' && alter != '' && (
            <DivEntity primaryColor={primaryColor} className='no-break'>
              <EncapsuleBoxTitle>
                <p>
                  <i className='fas fa-exchange-alt' /> CONDIÇÕES DE ALTERAÇÕES
                  <Hr></Hr>
                </p>
              </EncapsuleBoxTitle>
              <b style={{ fontSize: '14px', color: 'black' }}>
                <ImportantInformationObservation>
                  <DivForHTML
                    text={getFirstWords(alter, 2000)}
                    // {description}
                  />
                </ImportantInformationObservation>
              </b>
            </DivEntity>
          )}

          {cancel != '<p><br></p>' && cancel != '' && (
            <DivEntity primaryColor={primaryColor} className='no-break'>
              <EncapsuleBoxTitle>
                <p>
                  <i className='fas fa-times-circle' /> CONDIÇÕES DE
                  CANCELAMENTO
                  <Hr></Hr>
                </p>
              </EncapsuleBoxTitle>
              <b style={{ fontSize: '14px', color: 'black' }}>
                <ImportantInformationObservation>
                  <DivForHTML
                    text={getFirstWords(cancel, 2000)}
                    // {description}
                  />
                </ImportantInformationObservation>
              </b>
            </DivEntity>
          )}

          {reservation != '<p><br></p>' && reservation != '' && (
            <DivEntity primaryColor={primaryColor} className='no-break'>
              <EncapsuleBoxTitle>
                <p>
                  <i className='fas fa-check' /> CONDIÇÕES DE RESERVA
                  <Hr></Hr>
                </p>
              </EncapsuleBoxTitle>
              <b style={{ fontSize: '14px', color: 'black' }}>
                <ImportantInformationObservation>
                  <DivForHTML
                    text={getFirstWords(reservation, 2000)}
                    // {description}
                  />
                </ImportantInformationObservation>
              </b>
            </DivEntity>
          )}

          {observation != '<p><br></p>' && observation != '' && (
            <DivEntity primaryColor={primaryColor} className='no-break'>
              <EncapsuleBoxTitle>
                <p>
                  <i className='fas fa-map-marked-alt' /> OBSERVAÇÕES
                  IMPORTANTES
                  <Hr></Hr>
                </p>
              </EncapsuleBoxTitle>
              <b style={{ fontSize: '14px', color: 'black' }}>
                <ImportantInformationObservation>
                  <DivForHTML
                    text={getFirstWords(observation, 2000)}
                    // {description}
                  />
                </ImportantInformationObservation>
              </b>
            </DivEntity>
          )}

          {countryExi != '<p><br></p>' && countryExi != '' && (
            <DivEntity primaryColor={primaryColor} className='no-break'>
              <EncapsuleBoxTitle>
                <p>
                  <i className='fas fa-map-marked-alt' /> EXIGÊNCIAS DOS PAÍSES
                  <Hr></Hr>
                </p>
              </EncapsuleBoxTitle>
              <b style={{ fontSize: '14px', color: 'black' }}>
                <ImportantInformationObservation>
                  <DivForHTML
                    text={getFirstWords(countryExi, 2000)}
                    // {description}
                  />
                </ImportantInformationObservation>
              </b>
            </DivEntity>
          )}

          {fullValue && !showTotalValue ? (
            <DivEntity primaryColor={primaryColor} className='no-break'>
              <EncapsuleBoxTitle>
                <p>
                  <i className='fas fa-money-check-alt' /> VALOR TOTAL DA
                  PROPOSTA
                  <Hr></Hr>
                </p>
              </EncapsuleBoxTitle>
              <Table primaryColor='#c0c0c0' alternativeColorFont='#000'>
                <tr>
                  {!dolar ? (
                    <>
                      <th>TOTAL</th>
                      <th>{FormatValueBRL(fullValue)}</th>
                    </>
                  ) : (
                    <>
                      <th>TOTAL</th>
                      <th>{FormatValueUSD(fullValue)}</th>
                    </>
                  )}
                </tr>
              </Table>
            </DivEntity>
          ) : (
            totalValue != null &&
            !showTotalValue && (
              <DivEntity primaryColor={primaryColor} className='no-break'>
                <EncapsuleBoxTitle>
                  <p>
                    <i className='fas fa-money-check-alt' /> VALOR TOTAL DA
                    PROPOSTA
                    <Hr></Hr>
                  </p>
                </EncapsuleBoxTitle>
                <Table primaryColor='#c0c0c0' alternativeColorFont='#000'>
                  <TotalValueLine>
                    {!dolar ? (
                      <>
                        <td>TOTAL</td>
                        <td>{FormatValueBRL(totalValue)}</td>
                      </>
                    ) : (
                      <>
                        <td>TOTAL</td>
                        <td>{FormatValueUSD(totalValue)}</td>
                      </>
                    )}
                  </TotalValueLine>
                </Table>
              </DivEntity>
            )
          )}
        </Container>
        <Footer image={agency} />
      </DivGlobal>
    </div>
  )
}
