import { toast } from 'react-toastify'
import DownloadFile from './DownloadFile'

function getBase64(file, callback) {
  try {
    if (file && typeof file === 'object') {
      const reader = new FileReader()

      reader.onload = () => {
        callback(reader.result)
      }

      reader.readAsDataURL(file)
    }
  } catch (error) {
    callback(undefined)
  }
}

const fileToBase64 = file => {
  if (file == null) return null
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = reject
  })
}

async function getBlob(data) {
  try {
    const response = await fetch(`data:image/*;base64,${data}`)

    if (response.ok) {
      const blob = await response.blob()

      return blob
    }
  } catch (error) {
    return undefined
  }
}

function onDowloadCallback(e, x) {
  DownloadFile(e, x)
}

async function newDownloadFile(e, text) {
  getBase64(e, x => onDowloadCallback(x, e.name))

  if (text) {
    toast.info(text)
  }
}

async function getFile(base64, filename, mimetype) {
  const url = `data:${mimetype};base64,${base64}`

  const res = await fetch(url)

  const buf = await res.arrayBuffer()

  return new File([buf], filename, { type: mimetype })
}

export { getBase64, getBlob, newDownloadFile, getFile, fileToBase64 }
