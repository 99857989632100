import { useDispatch, useSelector } from 'react-redux'
import { Container } from '~/styles/container'
import HoldingBackofficeModule from '~/modules/HoldingBackoffice'
import HoldingConsolidatorModule from '~/modules/HoldingConsolidator'
import UnloggedModule from '~/modules/Unlogged'
import AgencyRoutes from './Agency'
import { useEffect } from 'react'
import { signOut } from '~/store/root/slices/auth'

export default function RoutesController() {
  const dispatch = useDispatch()
  const auth = useSelector(state => state.auth)

  useEffect(() => {
    if (
      auth &&
      auth.user &&
      !auth.user.isAgency &&
      !auth.user.isHoldingBackoffice &&
      !auth.user.isHoldingConsolidator
    ) {
      dispatch(signOut())
    }
  }, [])

  return (
    <Container style={{ height: '100%' }}>
      <>
        {!auth.signed && <UnloggedModule />}

        {auth.signed && (
          <>
            {auth.user.isAgency && <AgencyRoutes />}

            {auth.user.isHoldingConsolidator && <HoldingConsolidatorModule />}

            {auth.user.isHoldingBackoffice && <HoldingBackofficeModule />}
          </>
        )}
      </>
    </Container>
  )
}
