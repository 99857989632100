import { useSelector } from 'react-redux'
import { Route, Routes } from 'react-router-dom'

import Reminders from '~/pages/Reminder'
import ReminderList from '~/pages/Reminder/List'
import ReminderDetails from '~/pages/Reminder/Details'
import ReminderCreate from '~/pages/Reminder/Create'

import About from '~/pages/About'
import Profile from '~/pages/Profile'
import RedirectPage from '~/pages/RedirectPage'
import Schedule from '~/pages/Schedule'
import DefaultLayout from '~/pages/__layouts__/DefaultLayout'

import TravelGroup from '~/pages/TravelGroups'
import TravelGrouList from '~/pages/TravelGroups/List'
import TravelGrouCreate from '~/pages/TravelGroups/Create'
import TravelGrouDetails from '~/pages/TravelGroups/Details'

import Travels from '~/pages/Travels'
import TravelsCreate from '~/pages/Travels/Create'
import TravelsDetails from '~/pages/Travels/Details'
import TravelsDetailsDocuments from '~/pages/Travels/Details/Documents'
import TravelsList from '~/pages/Travels/List'

import ImportCustomer from '~/pages/Import'

import TravelDestiny from '~/pages/Travels/Details/Destiny'
import TravelDestinyAdd from '~/pages/Travels/Details/Destiny/Add'
import TravelDestinyDetails from '~/pages/Travels/Details/Destiny/Details'
import TravelDestinyList from '~/pages/Travels/Details/Destiny/List'

import TravelTravelers from '~/pages/Travels/Details/Travelers'
import TravelTravelersAdd from '~/pages/Travels/Details/Travelers/Create'
import TravelTravelersDetails from '~/pages/Travels/Details/Travelers/Details'
import TravelTravelersList from '~/pages/Travels/Details/Travelers/List'

import TravelInsurance from '~/pages/Travels/Details/Insurance'
import TravelInsuranceAdd from '~/pages/Travels/Details/Insurance/Create'
import TravelInsuranceDetails from '~/pages/Travels/Details/Insurance/Details'
import TravelInsuranceList from '~/pages/Travels/Details/Insurance/List'

import TravelExperience from '~/pages/Travels/Details/Experience'
import TravelExperienceAdd from '~/pages/Travels/Details/Experience/Add'
import TravelExperienceDetails from '~/pages/Travels/Details/Experience/Details'
import TravelExperienceList from '~/pages/Travels/Details/Experience/List'

import TravelPresentation from '~pages/Travels/Details/Presentation'

import RoadMap from '~/pages/Travels/Details/RoadMap'

import Destinys from '~/pages/Destinys'
import DestinysCreate from '~/pages/Destinys/Create'
import DestinysDetails from '~/pages/Destinys/Details'
import DestinysList from '~/pages/Destinys/List'

import HotelLibrary from '~/pages/Library/Hotel'
import HotelCreate from '~/pages/Library/Hotel/Create'
import HotelDetails from '~/pages/Library/Hotel/Details'
import HotelList from '~/pages/Library/Hotel/List'

import Experiences from '~/pages/Experiences'
import ExperiencesCreate from '~/pages/Experiences/Create'
import ExperiencesDetails from '~/pages/Experiences/Details'
import ExperiencesEdit from '~/pages/Experiences/Edit'
import ExperiencesList from '~/pages/Experiences/List'

import CreateDocument from '~/pages/Travels/Details/Documents/components/CreateDocument'
import EditDocument from '~/pages/Travels/Details/Documents/components/EditDocument'

import usePermissions from '~/hooks/usePermissions'
import Categories from '~/pages/Categories'
import CategoriesCreate from '~/pages/Categories/Create'
import CategoriesDetails from '~/pages/Categories/Details'
import CategoriesEdit from '~/pages/Categories/Edit'
import CategoriesList from '~/pages/Categories/List'

import Agencies from '~/pages/Agencies'
import AgencyCreate from '~/pages/Agencies/Create'
import AgencyDetails from '~/pages/Agencies/Details'
import AgencyList from '~/pages/Agencies/List'

import IntegrationAgency from '~/pages/IntegrationAgency'
import IntegrationAgencyList from '~/pages/IntegrationAgency/List'
import IntegrationAgencyCreate from '~/pages/IntegrationAgency/Create'
import IntegrationAgencyDetails from '~/pages/IntegrationAgency/Details'

import TagsAgency from '~/pages/TagsAgency'
import TagsAgencyList from '~/pages/TagsAgency/List'
import TagsAgencyCreate from '~/pages/TagsAgency/Create'
import TagsAgencyDetails from '~/pages/TagsAgency/Details'

import Motivation from '~/pages/Motivation'
import MotivationList from '~/pages/Motivation/List'
import MotivationCreate from '~/pages/Motivation/Create'
import MotivationDetails from '~/pages/Motivation/Details'

import Campaign from '~/pages/Campaign'
import CampaignList from '~/pages/Campaign/List'
import CampaignCreate from '~/pages/Campaign/Create'
import CampaignDetails from '~/pages/Campaign/Details'

import Observation from '~/pages/Observation'
import ObservationList from '~/pages/Observation/List'
import ObservationCreate from '~/pages/Observation/Create'
import ObservationDetails from '~/pages/Observation/Details'

import Partner from '~/pages/Partner'
import PartnerCreate from '~/pages/Partner/Create'
import PartnerDetails from '~/pages/Partner/Details'
import PartnerList from '~/pages/Partner/List'

import GlobalNotifications from '~/pages/GlobalNotifications'
import GlobalNotificationsCreate from '~/pages/GlobalNotifications/Create'
import GlobalNotificationsEdit from '~/pages/GlobalNotifications/Edit'
import GlobalNotificationsList from '~/pages/GlobalNotifications/List'

import Cities from '~/pages/Cities'
import CityCreate from '~/pages/Cities/Create'
import CityDetails from '~/pages/Cities/Details'
import CityList from '~/pages/Cities/List'

import Agents from '~/pages/Agents'
import AgentCreate from '~/pages/Agents/Create'
import AgentDetails from '~/pages/Agents/Details'
import AgentList from '~/pages/Agents/List'

import ReportHotelCheckIn from '~/pages/ReportHotelCheckIn'
import ReportHotelCheckInDetails from '~/pages/ReportHotelCheckIn/Details'
import ReportHotelCheckInList from '~/pages/ReportHotelCheckIn/List'

import ReportCheckIn from '~/pages/ReportCheckIn'
import ReportCheckInDetails from '~/pages/ReportCheckIn/Details'
import ReportCheckInList from '~/pages/ReportCheckIn/List'

import ReportHotelHosp from '~/pages/ReportHotelHosp'
import ReportHotelHospDetails from '~/pages/ReportHotelHosp/Details'
import ReportHotelHospList from '~/pages/ReportHotelHosp/List'

import ReportHotelProx from '~/pages/ReportHotelProx'
import ReportHotelProxDetails from '~/pages/ReportHotelProx/Details'
import ReportHotelProxList from '~/pages/ReportHotelProx/List'

import ReportEmbarque from '~/pages/ReportEmbarque'
import ReportEmbarqueDetails from '~/pages/ReportEmbarque/Details'
import ReportEmbarqueList from '~/pages/ReportEmbarque/List'

import ReportEmbarqueCopy from '~/pages/ReportEmbarque copy'
import ReportEmbarqueDetailsCopy from '~/pages/ReportEmbarque copy/Details'
import ReportEmbarqueListCopy from '~/pages/ReportEmbarque copy/List'

import ReportRankingAgentes from '~/pages/ReportRankingAgentes'
import ReportRankingAgentesDetails from '~/pages/ReportRankingAgentes/Details'
import ReportRankingAgentesList from '~/pages/ReportRankingAgentes/List'

import ReportAniversariantes from '~/pages/ReportAniversariantes'
import ReportAniversariantesDetails from '~/pages/ReportAniversariantes/Details'
import ReportAniversariantesList from '~/pages/ReportAniversariantes/List'

import ReportServices from '~/pages/ReportServices'
import ReportServicesDetails from '~/pages/ReportServices/Details'
import ReportServicesList from '~/pages/ReportServices/List'

import RelationshipCustomers from '~/pages/RelationshipCustomers'
import RelationshipCustomersCreate from '~/pages/RelationshipCustomers/Create'
import RelationshipCustomersDetails from '~/pages/RelationshipCustomers/Details'
import RelationshipCustomersList from '~/pages/RelationshipCustomers/List'
import RelationshipCustomersContactRegister from '~/pages/RelationshipCustomers/ContactRegister'
import RelationshipCustomersContactEnrollment from '~/pages/RelationshipCustomers/ContactEnrollment'
import RelationshipCustomersContactEnrollmentCreate from '~/pages/RelationshipCustomers/ContactEnrollment/Create'
import RelationshipCustomersContactEnrollmentList from '~/pages/RelationshipCustomers/ContactEnrollment/List'
import RelationshipCustomersContactEnrollmentDetails from '~/pages/RelationshipCustomers/ContactEnrollment/Details'

import RelationshipContactRegistered from '~/pages/RelationshipContactRegistered'
import RelationshipContactRegisteredList from '~/pages/RelationshipContactRegistered/List'

import RelationshipTravelHistory from '~/pages/RelationshipTravelHistory'
import RelationshipTravelHistoryList from '~/pages/RelationshipTravelHistory/List'

import RelationshipKanban from '~/pages/RelationshipKanban'

import RelationshipSearch from '~/pages/RelationshipSearch'
import RelationshipSearchList from '~/pages/RelationshipSearch/List'

import Refund from '~/pages/Refund'
import RefundDetails from '~/pages/Refund/Details'
import RefundList from '~/pages/Refund/List'

import Travelers from '~/pages/Travelers'
import TravelersCreate from '~/pages/Travelers/Create'
import TravelersDetails from '~/pages/Travelers/Details'
import TravelersList from '~/pages/Travelers/List'
import TravelersDocuments from '~/pages/Travelers/Documents'
import TravelersDocumentTable from '~/pages/Travelers/Documents/components/TravelersDocumentTable'
import TravelersCreateDocument from '~/pages/Travelers/Documents/components/TravelersCreateDocument'
import TravelersEditDocument from '~/pages/Travelers/Documents/components/Details'

import GeneralNotifications from '~/pages/GeneralNotifications'
import GeneralNotificationsCreate from '~/pages/GeneralNotifications/Create'
import GeneralNotificationsEdit from '~/pages/GeneralNotifications/Edit'
import GeneralNotificationsList from '~/pages/GeneralNotifications/List'
import Notifications from '~/pages/Notifications'
import NotificationsCreate from '~/pages/Notifications/Create'
import NotificationsCreatePush from '~/pages/Notifications/CreatePush'
import NotificationsEdit from '~/pages/Notifications/Edit'
import NotificationsList from '~/pages/Notifications/List'
import Agency from '~/pages/Agency'
import PortfolioOfCities from '~/pages/PortfolioOfCities/List'

import School from '~/pages/School'
import SchoolList from '~/pages/School/List'
import SchoolDetails from '~/pages/School/Details'
import SchoolParticipantKanban from '~/pages/School/ParticipantKanban'

import PedagogicalEvent from '~/pages/PedagogicalEvent'
import EventList from '~/pages/PedagogicalEvent/List/event'
import DocumentsEvent from '~/pages/PedagogicalEvent/Documents'
import DocumentsEventCreate from '~/pages/PedagogicalEvent/Documents/components/EventCreateDocument'
import DocumentsEventList from '~/pages/PedagogicalEvent/Documents/components/EventDocumentTable'
import DocumentsEventEdit from '~/pages/PedagogicalEvent/Documents/components/EventEditDocument'
import InstitutionList from '~/pages/PedagogicalEvent/List/institution'
import ResultList from '~/pages/PedagogicalEvent/List'
import CreateEvent from '~/pages/PedagogicalEvent/Create/event'
import CreateInstitution from '~/pages/PedagogicalEvent/Create/institution'
import EventDetails from '~/pages/PedagogicalEvent/Details/event'
import InstitutionDetails from '~/pages/PedagogicalEvent/Details/institution'
import ResultDetails from '~/pages/PedagogicalEvent/Details'
import PedagogicalParticipantKanban from '~/pages/PedagogicalEvent/ParticipantKanban'

import IntegrationRequest from '~/pages/IntegrationRequest'
import IntegrationRequestDetails from '~/pages/IntegrationRequest/Details'
import IntegrationRequestList from '~/pages/IntegrationRequest/List'

import IntegrationRequestToMaster from '~/pages/IntegrationRequest/ToMaster'
import IntegrationRequestDetailsToMaster from '~/pages/IntegrationRequest/ToMaster/Details'
import IntegrationRequestListToMaster from '~/pages/IntegrationRequest/ToMaster/List'

import Sales from '~/pages/Sales'
import SalesByIssuer from '~/pages/SalesByIssuer'
import SalesBackoffice from '~/pages/SalesBackoffice'
import SalesBackofficeByIssuer from '~/pages/SalesBackofficeByIssuer'

import Proposal from '~/pages/Proposal'
import ProposalList from '~/pages/Proposal/List'
import ProposalCreate from '~/pages/NewProposal/Create'
import ProposalDetails from '~/pages/NewProposal/Details'
import ProposalAvailability from '~/pages/Proposal/Availability'

import Radar from '~/pages/Radar'
import RadarList from '~/pages/Radar/List'
import RadarDetails from '~/pages/Radar/Details'
const CustomerServiceChat = React.lazy(() =>
  import('~/pages/CustomerServiceChat/index'),
)
import CustomerServiceChatLayout from '~/pages/__layouts__/CustomerServiceChatLayout'
import ErrorBoundary from '~/components/ErrorBoundary'
import React, { Suspense } from 'react'
import ChatErrorFallbackScreen from '~/pages/CustomerServiceChat/ChatErrorFallbackScreen'
import ChatSkeleton from '~/pages/CustomerServiceChat/Skeleton'
import SalesReleases from '~/pages/SalesReleases'
import SalesReleasesGeneralData from '~/pages/SalesReleasesGeneralData'
import BaseRoutes from '../base'
import context from '~/context/createContext'
import { useContext } from 'react'

import { Navigate } from 'react-router-dom'
import HoldingHeader from '~/modules/HoldingBackoffice/components/Header'
import Home from '~/modules/HoldingBackoffice/pages/Home'
import HoldingBackofficeProposals from '~/modules/HoldingBackoffice/pages/Proposals'
import HoldingBackofficeSales from '~/modules/HoldingBackoffice/pages/Sales'
// import HoldingHeader from './components/Header'
import useHoldingBackofficeData from '~/modules/HoldingBackoffice/dataHooks/useHoldingBackoffice'
import HoldingBackofficeCustomers from '~/modules/HoldingBackoffice/pages/Customers'
// import HoldingBackofficeLayout from '~/pages/__layouts__/HoldingBackofficeLayout'
// import LoadingContainer from '~/components/LoadingContainer'

export default function AgencyRoutes() {
  const auth = useSelector(state => state.auth)
  const {
    viewAgencies,
    manageAgencies,
    viewAgents,
    manageAgents,
    viewTravelers,
    manageTravelers,
    viewTravels,
    manageTravels,
    viewExperiences,
    manageExperiences,
    viewDestinys,
    manageDestinys,
    viewGeneralNotifications,
    manageGeneralNotifications,
    viewNotifications,
    manageNotifications,
    viewRelationship,
    manageRelationship,
    viewStrategy,
    manageStrategy,
    viewRefund,
    viewSchool,
    viewEvent,
    viewSales,
    isAdmin,
    viewPartner,
    viewRadar,
    isMaster,
  } = usePermissions()

  const { handleProfile, showSalesStats } = useContext(context)

  const {
    branchs,
    filters,
    branchsOptions,
    consultantOptions,
    changeFilters,
    resetFilters,
  } = useHoldingBackofficeData({ handleProfile })

  const Header = ({ title }) => {
    return (
      <HoldingHeader
        title={title}
        filters={filters}
        onFilter={changeFilters}
        onReset={resetFilters}
        branchsOptions={branchsOptions}
        handleProfile={handleProfile}
      />
    )
  }

  return (
    <Routes>
      <>
        {BaseRoutes()}

        {auth.user.role == 'library' && viewDestinys && viewExperiences && (
          <>
            <Route
              path='/destinys'
              exact
              element={
                <DefaultLayout>
                  <Destinys />
                </DefaultLayout>
              }
            >
              <Route index element={<DestinysList />} />

              <Route path={'details'} element={<DestinysDetails />} />

              {manageDestinys && (
                <>
                  <Route path={'create'} element={<DestinysCreate />} />
                </>
              )}
            </Route>

            <Route
              path='/library/hotel'
              exact
              element={
                <DefaultLayout>
                  <HotelLibrary />
                </DefaultLayout>
              }
            >
              <Route index element={<HotelList />} />

              <Route path={'details'} element={<HotelDetails />} />

              {manageDestinys && (
                <>
                  <Route path={'create'} element={<HotelCreate />} />
                </>
              )}
            </Route>

            <Route
              path='/experiences'
              exact
              element={
                <DefaultLayout>
                  <Experiences />
                </DefaultLayout>
              }
            >
              <Route index element={<ExperiencesList />} />
              <Route path={'details'} element={<ExperiencesDetails />} />

              {manageExperiences && (
                <>
                  <Route path={'create'} element={<ExperiencesCreate />} />
                  <Route path={'edit'} element={<ExperiencesEdit />} />

                  <Route path={'categories'} element={<Categories />}>
                    <Route index element={<CategoriesList />} />
                    <Route path={'details'} element={<CategoriesDetails />} />
                    <Route path={'create'} element={<CategoriesCreate />} />
                    <Route path={'edit'} element={<CategoriesEdit />} />
                  </Route>
                </>
              )}
            </Route>

            <Route
              path='/'
              element={
                <DefaultLayout>
                  <RedirectPage />
                </DefaultLayout>
              }
            />

            <Route
              path='*'
              element={
                <DefaultLayout>
                  <RedirectPage />
                </DefaultLayout>
              }
            />

            <Route
              path='/profile'
              exact
              element={
                <DefaultLayout>
                  <Profile />
                </DefaultLayout>
              }
            />
          </>
        )}

        {auth.user.role != 'library' && (
          <>
            {handleProfile && (
              <>
                <Route
                  path={'/home'}
                  exact
                  element={
                    <DefaultLayout>
                      <Header />

                      <Home
                        filters={filters}
                        branchs={branchs}
                        handleProfile={handleProfile}
                        showSalesStats={showSalesStats}
                      />
                    </DefaultLayout>
                  }
                />

                <Route
                  path={'/customers'}
                  exact
                  element={
                    <DefaultLayout>
                      <Header />

                      <HoldingBackofficeCustomers
                        filters={filters}
                        handleProfile={handleProfile}
                        consultantOptions={consultantOptions}
                      />
                    </DefaultLayout>
                  }
                />

                <Route
                  path={'/sales'}
                  exact
                  element={
                    <DefaultLayout>
                      <Header />

                      <HoldingBackofficeSales
                        filters={filters}
                        handleProfile={handleProfile}
                      />
                    </DefaultLayout>
                  }
                />

                <Route
                  path={'/proposals'}
                  exact
                  element={
                    <DefaultLayout>
                      <Header />

                      <HoldingBackofficeProposals
                        filters={filters}
                        handleProfile={handleProfile}
                      />
                    </DefaultLayout>
                  }
                />

                <Route
                  path='/about'
                  exact
                  element={
                    <DefaultLayout>
                      <About />
                    </DefaultLayout>
                  }
                />

                <Route
                  path='/reminders'
                  element={
                    <DefaultLayout>
                      <Reminders />
                    </DefaultLayout>
                  }
                >
                  <Route index element={<ReminderList />} />

                  <Route path='details' exact element={<ReminderDetails />} />
                  <Route path='create' exact element={<ReminderCreate />} />
                </Route>

                <>
                  <Route
                    path='/agency'
                    exact
                    element={
                      <DefaultLayout>
                        <Agency />
                      </DefaultLayout>
                    }
                  />

                  <Route
                    path='/integrationagency'
                    element={
                      <DefaultLayout>
                        <IntegrationAgency />
                      </DefaultLayout>
                    }
                  >
                    <Route index element={<IntegrationAgencyList />} />

                    <Route
                      path='create'
                      exact
                      element={<IntegrationAgencyCreate />}
                    />

                    <Route
                      path='details'
                      exact
                      element={<IntegrationAgencyDetails />}
                    />
                  </Route>

                  <Route
                    path='/tagsagency'
                    element={
                      <DefaultLayout>
                        <TagsAgency />
                      </DefaultLayout>
                    }
                  >
                    <Route index element={<TagsAgencyList />} />

                    <Route path='create' exact element={<TagsAgencyCreate />} />

                    <Route
                      path='details'
                      exact
                      element={<TagsAgencyDetails />}
                    />
                  </Route>

                  <Route
                    path='/motivation'
                    element={
                      <DefaultLayout>
                        <Motivation />
                      </DefaultLayout>
                    }
                  >
                    <Route index element={<MotivationList />} />

                    <Route path='create' exact element={<MotivationCreate />} />

                    <Route
                      path='details'
                      exact
                      element={<MotivationDetails />}
                    />
                  </Route>

                  <Route
                    path='/campaign'
                    element={
                      <DefaultLayout>
                        <Campaign />
                      </DefaultLayout>
                    }
                  >
                    <Route index element={<CampaignList />} />

                    <Route path='create' exact element={<CampaignCreate />} />

                    <Route path='details' exact element={<CampaignDetails />} />
                  </Route>

                  <Route
                    path='/observation'
                    element={
                      <DefaultLayout>
                        <Observation />
                      </DefaultLayout>
                    }
                  >
                    <Route index element={<ObservationList />} />

                    <Route
                      path='create'
                      exact
                      element={<ObservationCreate />}
                    />

                    <Route
                      path='details'
                      exact
                      element={<ObservationDetails />}
                    />
                  </Route>

                  <Route
                    path='/portfolio-of-cities'
                    exact
                    element={
                      <DefaultLayout>
                        <PortfolioOfCities />
                      </DefaultLayout>
                    }
                  />
                </>

                <>
                  <Route
                    path='/agents'
                    element={
                      <DefaultLayout>
                        <Agents />
                      </DefaultLayout>
                    }
                  >
                    <Route index element={<AgentList />} />

                    {manageAgents && (
                      <>
                        <Route
                          path='details'
                          exact
                          element={<AgentDetails />}
                        />

                        {(isMaster || isAdmin) && (
                          <Route
                            path='create'
                            exact
                            element={<AgentCreate />}
                          />
                        )}
                      </>
                    )}
                  </Route>
                </>

                <Route
                  path='/partner'
                  exact
                  element={
                    <DefaultLayout>
                      <Partner />
                    </DefaultLayout>
                  }
                >
                  <Route index element={<PartnerList />} />
                  <Route path='create' element={<PartnerCreate />} />
                  <Route path='details' element={<PartnerDetails />} />
                </Route>

                <Route path='*' element={<Navigate to={'/home'} replace />} />
              </>
            )}

            {!handleProfile && (
              <>
                <>
                  <Route
                    path='/reminders'
                    element={
                      <DefaultLayout>
                        <Reminders />
                      </DefaultLayout>
                    }
                  >
                    <Route index element={<ReminderList />} />

                    <Route path='details' exact element={<ReminderDetails />} />
                    <Route path='create' exact element={<ReminderCreate />} />
                  </Route>
                </>
                <Route
                  path='/import-customer'
                  element={
                    <DefaultLayout>
                      <ImportCustomer />
                    </DefaultLayout>
                  }
                />
                <Route
                  path='/proposal'
                  exact
                  element={
                    <DefaultLayout>
                      <Proposal />
                    </DefaultLayout>
                  }
                >
                  <Route index element={<ProposalList />} />
                  <Route path={'create'} element={<ProposalCreate />} />
                  <Route
                    path={'availability'}
                    element={<ProposalAvailability />}
                  />
                  <Route path={'edit'} element={<ProposalDetails />} />
                </Route>
                <Route
                  path='/schedule'
                  exact
                  element={
                    <DefaultLayout>
                      <Schedule />
                    </DefaultLayout>
                  }
                />
                <>
                  <Route
                    path='/IntegrationRequest'
                    element={
                      <DefaultLayout>
                        <IntegrationRequest />
                      </DefaultLayout>
                    }
                  >
                    <Route index element={<IntegrationRequestList />} />

                    <Route
                      path='details'
                      exact
                      element={<IntegrationRequestDetails />}
                    />
                  </Route>
                </>
                <>
                  <Route
                    path='/IntegrationRequestToMaster'
                    element={
                      <DefaultLayout>
                        <IntegrationRequestToMaster />
                      </DefaultLayout>
                    }
                  >
                    <Route index element={<IntegrationRequestListToMaster />} />

                    <Route
                      path='details'
                      exact
                      element={<IntegrationRequestDetailsToMaster />}
                    />
                  </Route>
                </>

                {viewSales && (
                  <>
                    {Sales()}

                    {SalesBackoffice()}

                    {SalesByIssuer()}

                    {SalesBackofficeByIssuer()}

                    {SalesReleases()}

                    {SalesReleasesGeneralData()}
                  </>
                )}

                {viewAgencies && (
                  <>
                    <Route
                      path='/agencies'
                      element={
                        <DefaultLayout>
                          <Agencies />
                        </DefaultLayout>
                      }
                    >
                      <Route index element={<AgencyList />} />

                      {manageAgencies && (
                        <>
                          <Route
                            path='details'
                            exact
                            element={<AgencyDetails />}
                          />
                          <Route
                            path='create'
                            exact
                            element={<AgencyCreate />}
                          />
                        </>
                      )}
                    </Route>
                    <Route
                      path='/cities'
                      element={
                        <DefaultLayout>
                          <Cities />
                        </DefaultLayout>
                      }
                    >
                      <Route index element={<CityList />} />

                      {manageAgencies && (
                        <>
                          <Route
                            path='details'
                            exact
                            element={<CityDetails />}
                          />
                          <Route path='create' exact element={<CityCreate />} />
                        </>
                      )}
                    </Route>
                    <Route
                      path='/global-notifications'
                      exact
                      element={
                        <DefaultLayout>
                          <GlobalNotifications />
                        </DefaultLayout>
                      }
                    >
                      <Route index element={<GlobalNotificationsList />} />

                      {manageAgencies && (
                        <>
                          <Route
                            path={'/global-notifications/create'}
                            element={<GlobalNotificationsCreate />}
                          />
                          <Route
                            path={'edit'}
                            element={<GlobalNotificationsEdit />}
                          />
                        </>
                      )}
                    </Route>
                  </>
                )}
                {viewAgents && (
                  <>
                    <Route
                      path='/agents'
                      element={
                        <DefaultLayout>
                          <Agents />
                        </DefaultLayout>
                      }
                    >
                      <Route index element={<AgentList />} />

                      {manageAgents && (
                        <>
                          <Route
                            path='details'
                            exact
                            element={<AgentDetails />}
                          />

                          {(isMaster || isAdmin) && (
                            <Route
                              path='create'
                              exact
                              element={<AgentCreate />}
                            />
                          )}
                        </>
                      )}
                    </Route>
                  </>
                )}
                {viewRelationship && auth.user.crmIncluded && (
                  <>
                    <Route
                      path='/relationship-customers'
                      element={
                        <DefaultLayout>
                          <RelationshipCustomers />
                        </DefaultLayout>
                      }
                    >
                      <Route index element={<RelationshipCustomersList />} />
                      {manageRelationship && (
                        <>
                          <Route
                            path='create'
                            exact
                            element={<RelationshipCustomersCreate />}
                          />
                          <Route
                            path='details'
                            exact
                            element={<RelationshipCustomersDetails />}
                          />
                          <Route
                            path='contact-register'
                            exact
                            element={<RelationshipCustomersContactRegister />}
                          />
                          <Route
                            path='enrollment'
                            exact
                            element={<RelationshipCustomersContactEnrollment />}
                          />
                        </>
                      )}
                    </Route>
                    <Route
                      path='/relationship-customers/enrollment-create'
                      exact
                      element={
                        <DefaultLayout>
                          <RelationshipCustomersContactEnrollmentCreate />
                        </DefaultLayout>
                      }
                    />
                    <Route
                      path='/relationship-customers/enrollment-list'
                      exact
                      element={
                        <DefaultLayout>
                          <RelationshipCustomersContactEnrollmentList />
                        </DefaultLayout>
                      }
                    />
                    <Route
                      path='/relationship-customers/enrollment-details'
                      exact
                      element={
                        <DefaultLayout>
                          <RelationshipCustomersContactEnrollmentDetails />
                        </DefaultLayout>
                      }
                    />
                  </>
                )}
                {viewRelationship && auth.user.crmIncluded && (
                  <>
                    <Route
                      path='/relationship-contact-registered'
                      element={
                        <DefaultLayout>
                          <RelationshipContactRegistered />
                        </DefaultLayout>
                      }
                    >
                      <Route
                        index
                        element={<RelationshipContactRegisteredList />}
                      />
                      {/* {manageRelationship && (
                  <>
                    <Route
                      path='details'
                      exact
                      element={<ReportHotelCheckInDetails />}
                    />
                  </>
                )} */}
                    </Route>
                  </>
                )}
                {viewRelationship && auth.user.crmIncluded && (
                  <>
                    <Route
                      path='/relationship-travel-history'
                      element={
                        <DefaultLayout>
                          <RelationshipTravelHistory />
                        </DefaultLayout>
                      }
                    >
                      <Route
                        index
                        element={<RelationshipTravelHistoryList />}
                      />
                      {/* {manageRelationship && (
                  <>
                    <Route
                      path='details'
                      exact
                      element={<ReportHotelCheckInDetails />}
                    />
                  </>
                )} */}
                    </Route>
                  </>
                )}
                {viewRadar && (
                  <>
                    <Route
                      path='/radar'
                      element={
                        <DefaultLayout>
                          <Radar />
                        </DefaultLayout>
                      }
                    >
                      <Route index element={<RadarList />} />
                      <Route
                        path={'details'}
                        index
                        element={<RadarDetails />}
                      />
                    </Route>
                  </>
                )}
                {viewRelationship && auth.user.crmIncluded && (
                  <>
                    <Route
                      path='/relationship-pipeline'
                      element={
                        <DefaultLayout>
                          <RelationshipKanban />
                        </DefaultLayout>
                      }
                    >
                      {/* <Route index element={<RelationshipKanbanList />} />

                {viewRelationship && (
                  <>
                    <Route
                      path='details'
                      exact
                      element={<ReportHotelHospDetails />}
                    />
                  </>
                )} */}
                    </Route>
                  </>
                )}
                {viewRelationship && (
                  <>
                    <Route
                      path='/relationship-search'
                      element={
                        <DefaultLayout>
                          <RelationshipSearch />
                        </DefaultLayout>
                      }
                    >
                      <Route index element={<RelationshipSearchList />} />

                      {/* {manageRelationship && (
                  <>
                    <Route
                      path='details'
                      exact
                      element={<ReportHotelProxDetails />}
                    />
                  </>
                )} */}
                    </Route>
                  </>
                )}
                {viewStrategy && (
                  <>
                    <Route
                      path='/report-hotelcheckin'
                      element={
                        <DefaultLayout>
                          <ReportHotelCheckIn />
                        </DefaultLayout>
                      }
                    >
                      <Route index element={<ReportHotelCheckInList />} />

                      {manageStrategy && (
                        <>
                          <Route
                            path='details'
                            exact
                            element={<ReportHotelCheckInDetails />}
                          />
                        </>
                      )}
                    </Route>
                  </>
                )}
                {viewStrategy && (
                  <>
                    <Route
                      path='/report-hotelhosp'
                      element={
                        <DefaultLayout>
                          <ReportHotelHosp />
                        </DefaultLayout>
                      }
                    >
                      <Route index element={<ReportHotelHospList />} />

                      {manageStrategy && (
                        <>
                          <Route
                            path='details'
                            exact
                            element={<ReportHotelHospDetails />}
                          />
                        </>
                      )}
                    </Route>
                  </>
                )}
                {viewStrategy && (
                  <>
                    <Route
                      path='/report-hotelreserv'
                      element={
                        <DefaultLayout>
                          <ReportHotelProx />
                        </DefaultLayout>
                      }
                    >
                      <Route index element={<ReportHotelProxList />} />

                      {manageStrategy && (
                        <>
                          <Route
                            path='details'
                            exact
                            element={<ReportHotelProxDetails />}
                          />
                        </>
                      )}
                    </Route>
                  </>
                )}
                {viewStrategy && (
                  <>
                    <Route
                      path='/report-hotelembarque'
                      element={
                        <DefaultLayout>
                          <ReportEmbarque />
                        </DefaultLayout>
                      }
                    >
                      <Route index element={<ReportEmbarqueList />} />

                      {manageStrategy && (
                        <>
                          <Route
                            path='details'
                            exact
                            element={<ReportEmbarqueDetails />}
                          />
                        </>
                      )}
                    </Route>
                  </>
                )}
                {viewStrategy && (
                  <>
                    <Route
                      path='/report-documents'
                      element={
                        <DefaultLayout>
                          <ReportEmbarqueCopy />
                        </DefaultLayout>
                      }
                    >
                      <Route index element={<ReportEmbarqueListCopy />} />

                      {manageStrategy && (
                        <>
                          <Route
                            path='details'
                            exact
                            element={<ReportEmbarqueDetailsCopy />}
                          />
                        </>
                      )}
                    </Route>
                  </>
                )}
                {viewStrategy && auth.user.crmIncluded && (
                  <>
                    <Route
                      path='/report-aniversariantes'
                      element={
                        <DefaultLayout>
                          <ReportAniversariantes />
                        </DefaultLayout>
                      }
                    >
                      <Route index element={<ReportAniversariantesList />} />

                      {manageStrategy && (
                        <>
                          <Route
                            path='details'
                            exact
                            element={<ReportAniversariantesDetails />}
                          />
                        </>
                      )}
                    </Route>
                  </>
                )}
                {viewStrategy && auth.user.crmIncluded && (
                  <>
                    <Route
                      path='/report-services'
                      element={
                        <DefaultLayout>
                          <ReportServices />
                        </DefaultLayout>
                      }
                    >
                      <Route index element={<ReportServicesList />} />

                      {manageStrategy && (
                        <>
                          <Route
                            path='details'
                            exact
                            element={<ReportServicesDetails />}
                          />
                        </>
                      )}
                    </Route>
                  </>
                )}
                {viewStrategy && (
                  <>
                    <Route
                      path='/report-checkin'
                      element={
                        <DefaultLayout>
                          <ReportCheckIn />
                        </DefaultLayout>
                      }
                    >
                      <Route index element={<ReportCheckInList />} />

                      {manageStrategy && (
                        <>
                          <Route
                            path='details'
                            exact
                            element={<ReportCheckInDetails />}
                          />
                        </>
                      )}
                    </Route>
                  </>
                )}
                {viewStrategy && (
                  <>
                    <Route
                      path='/report-ranking-agent'
                      element={
                        <DefaultLayout>
                          <ReportRankingAgentes />
                        </DefaultLayout>
                      }
                    >
                      <Route index element={<ReportRankingAgentesList />} />

                      {manageStrategy && (
                        <>
                          <Route
                            path='details'
                            exact
                            element={<ReportRankingAgentesDetails />}
                          />
                        </>
                      )}
                    </Route>
                  </>
                )}
                {viewRefund && (
                  <>
                    <Route
                      path='/refund'
                      element={
                        <DefaultLayout>
                          <Refund />
                        </DefaultLayout>
                      }
                    >
                      <Route index element={<RefundList />} />

                      <Route path='details' exact element={<RefundDetails />} />
                    </Route>
                  </>
                )}
                {viewTravelers && (
                  <Route
                    path='/travelers'
                    exact
                    element={
                      <DefaultLayout>
                        <Travelers />
                      </DefaultLayout>
                    }
                  >
                    <Route index element={<TravelersList />} />

                    {manageTravelers && (
                      <>
                        <Route path='details' element={<TravelersDetails />} />
                        <Route path='create' element={<TravelersCreate />} />
                        <Route
                          path='documents'
                          element={<TravelersDocuments />}
                        >
                          <Route index element={<TravelersDocumentTable />} />
                          <Route
                            path='create-document'
                            element={<TravelersCreateDocument />}
                          />
                          <Route
                            path='edit-document'
                            element={<TravelersEditDocument />}
                          />
                        </Route>
                      </>
                    )}
                  </Route>
                )}
                {viewPartner && (
                  <Route
                    path='/partner'
                    exact
                    element={
                      <DefaultLayout>
                        <Partner />
                      </DefaultLayout>
                    }
                  >
                    <Route index element={<PartnerList />} />
                    <Route path='create' element={<PartnerCreate />} />
                    <Route path='details' element={<PartnerDetails />} />
                  </Route>
                )}
                {viewTravels && (
                  <>
                    <Route
                      path='/group-travel'
                      exact
                      element={
                        <DefaultLayout>
                          <TravelGroup />
                        </DefaultLayout>
                      }
                    >
                      <Route index element={<TravelGrouList />} />
                      <Route path='create' element={<TravelGrouCreate />} />
                      <Route path='details' element={<TravelGrouDetails />} />
                    </Route>
                  </>
                )}
                {viewTravels && (
                  <>
                    <Route
                      path='/travels'
                      exact
                      element={
                        <DefaultLayout>
                          <Travels />
                        </DefaultLayout>
                      }
                    >
                      <Route index element={<TravelsList />} />

                      <Route path='details' element={<TravelsDetails />}>
                        <Route
                          path='presentation'
                          element={<TravelPresentation />}
                        />

                        <Route path='roadmap' element={<RoadMap />} />

                        <Route path='destiny' element={<TravelDestiny />}>
                          <Route index element={<TravelDestinyList />} />

                          <Route path='add' element={<TravelDestinyAdd />} />

                          <Route
                            path='details'
                            element={<TravelDestinyDetails />}
                          />
                        </Route>

                        <Route path='travelers' element={<TravelTravelers />}>
                          <Route index element={<TravelTravelersList />} />

                          <Route path='add' element={<TravelTravelersAdd />} />

                          <Route
                            path='details'
                            element={<TravelTravelersDetails />}
                          />
                        </Route>

                        <Route path='insurance' element={<TravelInsurance />}>
                          <Route index element={<TravelInsuranceList />} />

                          <Route path='add' element={<TravelInsuranceAdd />} />

                          <Route
                            path='details'
                            element={<TravelInsuranceDetails />}
                          />
                        </Route>

                        <Route path='experience' element={<TravelExperience />}>
                          <Route index element={<TravelExperienceList />} />

                          <Route path='add' element={<TravelExperienceAdd />} />

                          <Route
                            path='details'
                            element={<TravelExperienceDetails />}
                          />
                        </Route>

                        <Route
                          path='documents'
                          element={<TravelsDetailsDocuments />}
                        />

                        {manageTravels && (
                          <>
                            <Route
                              path='create-documents'
                              element={<CreateDocument />}
                            />
                            <Route
                              path='edit-documents'
                              element={<EditDocument />}
                            />
                          </>
                        )}
                      </Route>

                      {manageTravels && (
                        <>
                          <Route path='create' element={<TravelsCreate />} />
                        </>
                      )}
                    </Route>
                  </>
                )}
                {viewExperiences && (
                  <>
                    <Route
                      path='/experiences'
                      exact
                      element={
                        <DefaultLayout>
                          <Experiences />
                        </DefaultLayout>
                      }
                    >
                      <Route index element={<ExperiencesList />} />
                      <Route
                        path={'details'}
                        element={<ExperiencesDetails />}
                      />

                      {manageExperiences && (
                        <>
                          <Route
                            path={'create'}
                            element={<ExperiencesCreate />}
                          />
                          <Route path={'edit'} element={<ExperiencesEdit />} />

                          <Route path={'categories'} element={<Categories />}>
                            <Route index element={<CategoriesList />} />
                            <Route
                              path={'details'}
                              element={<CategoriesDetails />}
                            />
                            <Route
                              path={'create'}
                              element={<CategoriesCreate />}
                            />
                            <Route path={'edit'} element={<CategoriesEdit />} />
                          </Route>
                        </>
                      )}
                    </Route>
                  </>
                )}
                {viewDestinys && (
                  <>
                    <Route
                      path='/destinys'
                      exact
                      element={
                        <DefaultLayout>
                          <Destinys />
                        </DefaultLayout>
                      }
                    >
                      <Route index element={<DestinysList />} />

                      <Route path={'details'} element={<DestinysDetails />} />

                      {manageDestinys && (
                        <>
                          <Route path={'create'} element={<DestinysCreate />} />
                        </>
                      )}
                    </Route>

                    <Route
                      path='/library/hotel'
                      exact
                      element={
                        <DefaultLayout>
                          <HotelLibrary />
                        </DefaultLayout>
                      }
                    >
                      <Route index element={<HotelList />} />

                      <Route path={'details'} element={<HotelDetails />} />

                      {/* {manageDestinys && (
                    <> */}
                      <Route path={'create'} element={<HotelCreate />} />
                      {/* </>
                  )} */}
                    </Route>
                  </>
                )}
                {viewGeneralNotifications && (
                  <>
                    <Route
                      path='/general-notifications'
                      exact
                      element={
                        <DefaultLayout>
                          <GeneralNotifications />
                        </DefaultLayout>
                      }
                    >
                      <Route index element={<GeneralNotificationsList />} />

                      {manageGeneralNotifications && (
                        <>
                          <Route
                            path={'create'}
                            element={<GeneralNotificationsCreate />}
                          />
                          <Route
                            path={'edit'}
                            element={<GeneralNotificationsEdit />}
                          />
                        </>
                      )}
                    </Route>
                  </>
                )}
                {viewNotifications && (
                  <>
                    <Route
                      path='/notifications'
                      exact
                      element={
                        <DefaultLayout>
                          <Notifications />
                        </DefaultLayout>
                      }
                    >
                      <Route index element={<NotificationsList />} />

                      {manageNotifications && (
                        <>
                          <Route
                            path={'create'}
                            element={<NotificationsCreate />}
                          />
                          <Route
                            path={'create-push'}
                            element={<NotificationsCreatePush />}
                          />
                          <Route
                            path={'edit'}
                            element={<NotificationsEdit />}
                          />
                        </>
                      )}
                    </Route>
                  </>
                )}
                {viewSchool && (
                  <Route
                    path='/school'
                    element={
                      <DefaultLayout>
                        <School />
                      </DefaultLayout>
                    }
                  >
                    <Route index element={<SchoolList />} />
                    <Route path='details' exact element={<SchoolDetails />} />
                    <Route
                      path='participants-kanban'
                      exact
                      element={<SchoolParticipantKanban />}
                    />
                  </Route>
                )}
                {viewEvent && (
                  <Route
                    path='/pedagogical-event'
                    element={
                      <DefaultLayout>
                        <PedagogicalEvent />
                      </DefaultLayout>
                    }
                  >
                    <Route index element={<ResultList />} />

                    <Route
                      path='create/event'
                      exact
                      element={<CreateEvent />}
                    />

                    <Route
                      path='create/institution'
                      exact
                      element={<CreateInstitution />}
                    />

                    <Route path='list/event' exact element={<EventList />} />

                    <Route
                      path='/pedagogical-event/documents/event'
                      exact
                      element={<DocumentsEvent />}
                    >
                      <Route
                        path='create'
                        exact
                        element={<DocumentsEventCreate />}
                      />

                      <Route
                        path='list'
                        exact
                        element={<DocumentsEventList />}
                      />

                      <Route
                        path='edit'
                        exact
                        element={<DocumentsEventEdit />}
                      />
                      {/* <Route
                  path='create/institution'
                  exact
                  element={<CreateInstitution />}
                /> */}
                    </Route>

                    <Route
                      path='list/institution'
                      exact
                      element={<InstitutionList />}
                    />

                    <Route path='details' exact element={<ResultDetails />} />

                    <Route
                      path='/pedagogical-event/list/institution/details'
                      exact
                      element={<InstitutionDetails />}
                    />

                    <Route
                      path='/pedagogical-event/list/event/details'
                      exact
                      element={<EventDetails />}
                    />

                    <Route
                      path='/pedagogical-event/kanban'
                      exact
                      element={<PedagogicalParticipantKanban />}
                    />
                  </Route>
                )}
                {isAdmin && (
                  <>
                    <Route
                      path='/agency'
                      exact
                      element={
                        <DefaultLayout>
                          <Agency />
                        </DefaultLayout>
                      }
                    />

                    <Route
                      path='/integrationagency'
                      element={
                        <DefaultLayout>
                          <IntegrationAgency />
                        </DefaultLayout>
                      }
                    >
                      <Route index element={<IntegrationAgencyList />} />

                      <Route
                        path='create'
                        exact
                        element={<IntegrationAgencyCreate />}
                      />

                      <Route
                        path='details'
                        exact
                        element={<IntegrationAgencyDetails />}
                      />
                    </Route>

                    <Route
                      path='/tagsagency'
                      element={
                        <DefaultLayout>
                          <TagsAgency />
                        </DefaultLayout>
                      }
                    >
                      <Route index element={<TagsAgencyList />} />

                      <Route
                        path='create'
                        exact
                        element={<TagsAgencyCreate />}
                      />

                      <Route
                        path='details'
                        exact
                        element={<TagsAgencyDetails />}
                      />
                    </Route>

                    <Route
                      path='/motivation'
                      element={
                        <DefaultLayout>
                          <Motivation />
                        </DefaultLayout>
                      }
                    >
                      <Route index element={<MotivationList />} />

                      <Route
                        path='create'
                        exact
                        element={<MotivationCreate />}
                      />

                      <Route
                        path='details'
                        exact
                        element={<MotivationDetails />}
                      />
                    </Route>

                    <Route
                      path='/campaign'
                      element={
                        <DefaultLayout>
                          <Campaign />
                        </DefaultLayout>
                      }
                    >
                      <Route index element={<CampaignList />} />

                      <Route path='create' exact element={<CampaignCreate />} />

                      <Route
                        path='details'
                        exact
                        element={<CampaignDetails />}
                      />
                    </Route>

                    <Route
                      path='/observation'
                      element={
                        <DefaultLayout>
                          <Observation />
                        </DefaultLayout>
                      }
                    >
                      <Route index element={<ObservationList />} />

                      <Route
                        path='create'
                        exact
                        element={<ObservationCreate />}
                      />

                      <Route
                        path='details'
                        exact
                        element={<ObservationDetails />}
                      />
                    </Route>

                    <Route
                      path='/portfolio-of-cities'
                      exact
                      element={
                        <DefaultLayout>
                          <PortfolioOfCities />
                        </DefaultLayout>
                      }
                    />
                  </>
                )}
                <Route
                  path='/profile'
                  exact
                  element={
                    <DefaultLayout>
                      <Profile />
                    </DefaultLayout>
                  }
                />
                <Route
                  path='/about'
                  exact
                  element={
                    <DefaultLayout>
                      <About />
                    </DefaultLayout>
                  }
                />
                <Route
                  path='/'
                  element={
                    <DefaultLayout>
                      <RedirectPage />
                    </DefaultLayout>
                  }
                />
                <Route
                  path='*'
                  element={
                    <DefaultLayout>
                      <RedirectPage />
                    </DefaultLayout>
                  }
                />
              </>
            )}
            <Route path='/profile' exact element={<Profile />} />
            <Route
              path='/about'
              exact
              element={
                <DefaultLayout>
                  <About />
                </DefaultLayout>
              }
            />
            <Route
              path='/'
              element={
                <DefaultLayout>
                  <RedirectPage />
                </DefaultLayout>
              }
            />
            <Route
              path='*'
              element={
                <DefaultLayout>
                  <RedirectPage />
                </DefaultLayout>
              }
            />
          </>
        )}
        {auth.tenant && (
          <Route
            path='/chat'
            exact
            element={
              <ErrorBoundary fallback={<ChatErrorFallbackScreen />}>
                <CustomerServiceChatLayout>
                  <Suspense fallback={<ChatSkeleton />}>
                    <CustomerServiceChat />
                  </Suspense>
                </CustomerServiceChatLayout>
              </ErrorBoundary>
            }
          />
        )}
      </>
    </Routes>
  )
}
