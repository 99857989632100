const mergeDuplicatedConversations = ({ conversations }) => {
  const newConversations = []

  conversations.forEach(x => {
    if (!isBrasilianPhoneWith9(x.phoneNumber)) {
      newConversations.push(x)
    }
  })

  conversations.forEach(x => {
    if (isBrasilianPhoneWith9(x.phoneNumber)) {
      const alternativePhone = getAlternativePhone(x.phoneNumber)

      if (!newConversations.find(y => y.phoneNumber === alternativePhone)) {
        newConversations.push(x)
      }
    }
  })

  const res = []

  newConversations.forEach(x => {
    const alternativePhone = getAlternativePhone(x.phoneNumber)

    const finded = conversations.find(y => y.phoneNumber === alternativePhone)

    if (finded && finded.timestamp > x.timestamp) {
      res.push({
        ...finded,
        phoneNumber: x.phoneNumber,
      })
    } else {
      res.push(x)
    }
  })

  return res
}

const getAlternativePhone = phone => {
  if (isBrasilianPhone(phone)) {
    if (phone.length === 12) {
      const ddi = phone.substring(0, 2)

      const ddd = phone.substring(2, 4)

      const phoneNumber = phone.substring(4, 12)

      return `${ddi}${ddd}9${phoneNumber}`
    }

    if (phone.length === 13) {
      const ddi = phone.substring(0, 2)

      const ddd = phone.substring(2, 4)

      const phoneNumber = phone.substring(5, 13)

      return `${ddi}${ddd}${phoneNumber}`
    }
  }

  return undefined
}

const isBrasilianPhone = phone => {
  if (isBrasilianPhoneWithout9(phone) || isBrasilianPhoneWith9(phone)) {
    return true
  }

  return false
}

const isBrasilianPhoneWithout9 = phone => {
  if (phone && phone.startsWith('55') && phone.length === 12) {
    return true
  }

  return false
}

const isBrasilianPhoneWith9 = phone => {
  if (phone && phone.startsWith('55') && phone.length === 13) {
    return true
  }

  return false
}

export { mergeDuplicatedConversations, getAlternativePhone, isBrasilianPhone }
