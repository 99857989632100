import {
  SET_CONTACTS,
  REMOVE_DATA,
  ADD_CONTACT,
  SET_CURRENT_SERVICE_CONTACTS,
} from '../actions/contactAction'

const initialState = {
  contacts: [],
  currentServiceContacts: [],
}

const contactReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_CONTACTS: {
      return {
        ...state,
        contacts: [...action.data],
      }
    }
    case ADD_CONTACT: {
      let newArray = state.contacts
      newArray.push(action.data)
      return {
        ...state,
        contacts: newArray,
      }
    }
    case REMOVE_DATA:
      return {
        ...state,
        contacts: [],
      }
    case SET_CURRENT_SERVICE_CONTACTS:
      return {
        ...state,
        currentServiceContacts: action.data,
      }
    default:
      return state
  }
}

export default contactReducer
