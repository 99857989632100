import ModalDefault from '~/components/ModalDefault'
import DefaultForm from '~/components/DefaultForm'
import { apiRequest } from '~/apiService'
import { useEffect, useState, useRef } from 'react'
import { toast } from 'react-toastify'
import { getFile } from '~/functions/File'
import { Panel, PanelBody, PanelHeader } from '~/components/Panel'
import ReactTable from '~/components/Table'
import { Create } from '~/components/TableToList/style'
import styled from 'styled-components'
import Divisor from '~/components/Divisor'
import IconButton from '~/components/IconButton'
import { getDateFormated } from '~/functions/Date'

export default function ModalAir({
  title = '',
  origin = '',
  id = 0,
  proposalId = 0,
  inputs = [],
  handle = () => {},
  content = '',
  setData,
  citiesList,
}) {
  const [loading, setLoading] = useState(false)
  const [loadingExcerpt, setLoadingExcerpt] = useState(false)
  const [openModal, setOpenModal] = useState(false)
  const [excerptDetails, setExcerptDetails] = useState(false)
  const [modaStage, setModalStage] = useState('1')
  const [defaultValue, setDefaultValue] = useState({})
  const [fData, setFData] = useState({})
  const [formAirData, setFormAirData] = useState({})
  const [defaultValueExcerpt, setDefaultValueExcerpt] = useState({})
  const [dataExcerpts, setDataExcerpts] = useState([])
  const [selectedFlights, setSelectedFlights] = useState([])
  const flightData = useRef([])
  const [excerptId, setExcerptId] = useState(0)
  const [flightId, setFlightId] = useState(id)
  const [defaultData, setDefaultData] = useState()
  const [currentFlightList, setCurrentFlightList] = useState(1)
  const [firstSearchIsDone, setFirstSearchIsDone] = useState(false)
  const [minDate, setMinDate] = useState(
    defaultValue?.dateOrigin ?? '1900-01-01',
  )
  const [checkedState, setCheckedState] = useState([])

  let operation = flightId == 0 ? 'post' : 'put'

  useEffect(() => {
    if (operation == 'put') {
      get()
      getAllExcerpt()
    }

    setCurrentFlightList(1)
    flightData.current = []
  }, [])

  const get = async () => {
    const result = await apiRequest(
      'get',
      `proposal/${origin}/${flightId}`,
      {},
      setLoading,
    )

    if (result.error) {
      toast.info(result?.erroMessage)
      return
    }

    const newResultHotel = {
      ...result?.data?.content,
      files: [],
    }

    if (result?.data?.content?.files?.length > 0) {
      for (let iF = 0; iF < result?.data?.content?.files?.length; iF++) {
        const file = await getFile(
          result?.data?.content?.files[iF]?.file,
          result?.data?.content?.files[iF]?.fileName,
          result?.data?.content?.files[iF]?.contentType,
        )

        newResultHotel?.files.push(file)
      }
    }

    for (let key in newResultHotel) {
      newResultHotel[key] === null && delete newResultHotel[key]
      newResultHotel[key]?.value === null && delete newResultHotel[key]
    }

    setDefaultValue(newResultHotel)
  }

  const getAllExcerpt = async () => {
    const result = await apiRequest(
      'options',
      `proposal/flight-excerpt/${id}`,
      {},
      setLoadingExcerpt,
    )

    if (result.error) {
      toast.info(result?.erroMessage)
      return
    }

    setDataExcerpts(result?.data?.content)
  }

  const getExcerpts = async id => {
    const result = await apiRequest(
      'get',
      `proposal/flight-excerpt/${id}`,
      {},
      setLoadingExcerpt,
    )

    if (result.error) {
      toast.info(result?.erroMessage)
      return
    }

    setDefaultValueExcerpt(result?.data?.content)
  }

  const verify = data => {
    if (!data.passengers) {
      toast.info('O campo N. adultos é obrigatório.')
      return false
    }
    // if (!data.numberOfChildren) {
    //   toast.info('O campo N. crianças é obrigatório.')
    //   return false
    // }
    // if (!data.numberOfBabies) {
    //   toast.info('O campo N. bêbes é obrigatório.')
    //   return false
    // }
    if (!data.baggage) {
      toast.info('O campo bagagem é obrigatório.')
      return false
    }
    if (!data.type) {
      toast.info('O campo tipo é obrigatório.')
      return false
    }

    return true
  }

  const onSubmit = async (data, setId, closeModal) => {
    if (verify(data)) {
      const formData = new FormData()

      for (let key in data) {
        if (data[key] != null) {
          if (key == 'files') {
            data?.files?.map(file => formData.append('files', file))
          } else {
            formData.append(key, data[key])
          }
        }
      }

      formData.set('proposalId', proposalId)
      formData.set('id', flightId)
      formData.set(
        'baggage',
        data?.baggage?.value == undefined ? false : data?.baggage?.value,
      )
      if (data?.passengersDescription?.value)
        formData.set(
          'passengersDescription',
          data?.passengersDescription?.value,
        )

      if (data?.type?.value) formData.set('type', data?.type?.value)

      const result = await apiRequest(
        operation,
        `proposal/${origin}`,
        formData,
        setLoading,
      )

      if (result.error) {
        toast.info(result?.erroMessage)
        return
      }

      if (setId) setFlightId(result.data.id)

      setData(state => {
        const aux = { ...state }

        aux[content] = result?.data?.content

        return aux
      })

      if (closeModal) handle()

      !result.error && toast.success(result?.data?.message)
    }
  }

  const onSubmitExcerpt = async data => {
    const result = await apiRequest(
      excerptId == 0 ? 'post' : 'put',
      `proposal/flight-excerpt`,
      { ...data, proposalId: proposalId, proposalFlightId: flightId },
      setLoadingExcerpt,
    )

    if (result.error) {
      toast.info(result?.erroMessage)
      return
    }

    setModalStage('1')
    setOpenModal(state => !state)
    setExcerptDetails(state => !state)
    setDataExcerpts(result?.data?.content)
    setDefaultValueExcerpt({})
    toast.success(result?.data?.message)
  }

  const onClone = async (route, id) => {
    const result = await apiRequest(
      'post',
      `proposal/clone-${route}`,
      { id: id, proposalId: proposalId },
      setLoading,
    )

    if (result.error) {
      toast.info(result?.erroMessage)
    } else {
      setDataExcerpts(result?.data?.content)
      toast.success(result?.data?.message)
    }
  }

  async function onSearch(e) {
    if (!e.target.value) {
      return
    }
    const result = await apiRequest(
      'get',
      `travelair/search-flight`,
      { flight: e.target.value.replace(' ', '') },
      setLoadingExcerpt,
    )

    if (result.error) {
      toast.info('Vôo não encontrado')
    } else {
      setDefaultValueExcerpt({
        ...defaultValueExcerpt,
        flight: e.target.value.replace(' ', ''),
        cia: result?.data?.response?.airline_name,
        iataOrigin: result?.data?.response?.dep_iata,
        airportOrigin: result?.data?.response?.dep_name,
        origin: result?.data?.response?.dep_city,
        iataDestiny: result?.data?.response?.arr_iata,
        airportDestiny: result?.data?.response?.arr_name,
        destiny: result?.data?.response?.arr_city,
      })
    }
  }

  async function onSearchRange(e) {
    try {
      let excerpts = []
      selectedFlights.forEach(sf => {
        sf.legs.forEach(l => {
          excerpts.push({
            flight: l.flightNumber,
            cia: sf.companyName,
            origin: l.departureCity,
            iataOrigin: l.departure,
            airportOrigin: l.departureName,
            destiny: l.arrivalCity,
            iataDestiny: l.arrival,
            airportDestiny: l.arrivalName,
            proposalId: proposalId,
            proposalFlightId: flightId,
            dateOrigin: getDateFormated(l.departureDate, 'YYYY/MM/DD HH:mm:ss'),
            timeOrigin: getDateFormated(l.departureDate, 'YYYY/MM/DD HH:mm:ss'),
            dateDestiny: getDateFormated(l.arrivalDate, 'YYYY/MM/DD HH:mm:ss'),
            timeDestiny: getDateFormated(l.arrivalDate, 'YYYY/MM/DD HH:mm:ss'),
            type: l.type,
          })
        })
      })

      excerpts.forEach(async expt => {
        const result = await apiRequest(
          expt.id ?? 0 == 0 ? 'post' : 'put',
          `proposal/flight-excerpt`,
          expt,
          setLoadingExcerpt,
        )

        if (result.error) {
          toast.info(result?.erroMessage)
          return
        }

        expt.id = (result?.data?.content ?? [{}])[0].id
      })

      setOpenModal(state => !state)
      setDataExcerpts(ex => [...ex, ...excerpts])

      setDefaultValueExcerpt({})
      setModalStage('1')

      toast.success('Trecho(s) adicionado(s) com sucesso')
    } catch (err) {
      toast.info(
        'Um erro inesperado ocorreu, por favor entrar em contato com o suporte.',
      )
    }
  }

  async function searchButtonHandler(e) {
    let jsonInputData = {
      Origem: e.fromAirport.value,
      Destino: e.toAirport.value,
      Ida: e.departureDate,
      Volta: e.returnDate ?? null,
      Adultos: fData.passengers,
      Criancas: fData.numberOfChildren ?? 0,
      Bebes: fData.numberOfBabies ?? 0,
      Companhia: -1,
      NumeroPagina: 1,
      filtros: {},
    }

    if (e.companyNameSearch) {
      jsonInputData.filtros.cias = [e.companyNameSearch.value]
    }

    if (e.numberOfStopovers) {
      jsonInputData.filtros.Escalas = [e.numberOfStopovers]
    }

    if (e.flightNumbers) {
      jsonInputData.filtros.NumeroVoo = e.flightNumbers?.split(',')
    }

    const result = await apiRequest(
      'post',
      `moblix/getflights`,
      jsonInputData,
      setLoadingExcerpt,
    )

    flightData.current = result?.data
    setCurrentFlightList(1)
    setFirstSearchIsDone(true)

    if ((result?.data?.flights?.segments ?? 0) == 0) {
      toast.info('Nenhum voo encontrado')
      setDefaultData(oldState => {
        return {
          ...oldState,
          flights: [],
        }
      })
    } else {
      setCheckedState(
        new Array(
          flightData.current?.flights?.segments.length +
            flightData.current?.flights?.segmentsBack.length,
        ).fill(false),
      )
      setDefaultData(oldState => {
        return {
          ...oldState,
          flights: flightData.current?.flights?.segments,
        }
      })
    }
  }

  async function onChangeIata(e) {
    if (!e.target.value) return

    const result = await apiRequest(
      'get',
      `travelair/search-airport`,
      { airportIata: e.target.value },
      setLoadingExcerpt,
    )

    if (result.error) {
      toast.info('Aeroporto não encontrado.')
    } else {
      if (e.target.name === 'iataOrigin') {
        setDefaultValueExcerpt({
          ...defaultValueExcerpt,
          iataOrigin: result.data.id,
          airportOrigin: result.data.name,
          origin: result.data.city,
        })
      }

      if (e.target.name === 'iataDestiny') {
        setDefaultValueExcerpt({
          ...defaultValueExcerpt,
          destinyIata: result.data.id,
          airportDestiny: result.data.name,
          destiny: result.data.city,
        })
      }
    }

    setLoading(false)
  }

  async function handleCheckboxClick(e, props) {
    if (e.target.checked) {
      setSelectedFlights([...selectedFlights, props.row.original])
    } else {
      setSelectedFlights(f => {
        return f.filter(ff => ff.id != props.row.original.id)
      })
    }
    setCheckedState(state => {
      let newCheckedState = [...state]
      newCheckedState[props.row.original.id] =
        !newCheckedState[props.row.original.id]
      return newCheckedState
    })
  }

  useEffect(() => {
    setDefaultData(oldState => {
      return {
        ...oldState,
        flights:
          currentFlightList == 1
            ? flightData.current?.flights?.segments
            : flightData.current?.flights?.segmentsBack,
      }
    })
  }, [currentFlightList])

  function formatDestinyDate(e) {
    const formatedDate = getDateFormated(e, 'YYYY-MM-DD')
    setMinDate(formatedDate)
    return formatedDate
  }

  const onRemove = async id => {
    const result = await apiRequest(
      'delete',
      `proposal/flight-excerpt/${id}`,
      {},
      setLoading,
    )

    if (result.error) {
      toast.info(result?.erroMessage)
      return
    }

    setDataExcerpts(result?.data?.content)

    !result.error && toast.success(result?.data?.message)
  }

  return (
    <ModalDefault
      title={title}
      handleShow={handle}
      content={
        <Container>
          {openModal && (
            <CustomModal>
              {modaStage == '1' && !excerptDetails && (
                <CustomModalContent>
                  <CustomModalContentHeader>
                    <div>
                      <h5>Trecho</h5>
                    </div>

                    <IconButton
                      type={'close'}
                      onClick={() => {
                        setDefaultValueExcerpt({})
                        setOpenModal(state => !state)
                        setExcerptId(0)
                        setModalStage('1')
                        setExcerptDetails(false)
                      }}
                    />
                  </CustomModalContentHeader>

                  <Divisor border />

                  <DefaultForm
                    loading={loadingExcerpt}
                    defaultValue={defaultData}
                    callbackSubmit={onSearchRange}
                    onChangeData={data => setFormAirData(data)}
                    callbackReset={() => {}}
                    callbackCancel={() => {
                      setModalStage('2')
                      setExcerptDetails(true)
                    }}
                    cancelLabel='Preencher manualmente'
                    submitLabel='Salvar e Fechar'
                    inputs={[
                      {
                        type: 'largeListSelect',
                        name: 'fromAirport',
                        label: 'Origem',
                        min: 0,
                        sm: 12,
                        lg: 2,
                        options: citiesList,
                        isClearable: true,
                      },
                      {
                        type: 'largeListSelect',
                        name: 'toAirport',
                        label: 'Destino',
                        min: 0,
                        sm: 12,
                        lg: 2,
                        options: citiesList,
                        isClearable: true,
                      },
                      {
                        type: 'date',
                        name: 'departureDate',
                        label: 'Data de Ida',
                        min: 0,
                        sm: 12,
                        lg: fData?.type?.value == 'Ida e Volta' ? 2 : 2,
                      },
                      ...(fData?.type?.value == 'Ida e Volta'
                        ? [
                            {
                              type: 'date',
                              name: 'returnDate',
                              label: 'Data de Volta',
                              min: 0,
                              sm: 12,
                              lg: 2,
                            },
                          ]
                        : []),
                      {
                        type: 'select',
                        name: 'companyNameSearch',
                        label: 'Companhia',
                        min: 0,
                        sm: 12,
                        lg: 2,
                        options: [
                          { label: 'Azul', value: 'Azul' },
                          { label: 'Latam', value: 'Latam' },
                          { label: 'Gol e Smiles', value: 'Gol e Smiles' },
                          { label: 'TAP', value: 'TAP' },
                          { label: 'Livelo', value: 'Livelo' },
                          { label: 'Azul Interline', value: 'Azul Interline' },
                        ],
                        isClearable: true,
                      },
                      {
                        type: 'number',
                        name: 'numberOfStopovers',
                        label: 'Quantidade de escalas',
                        min: 0,
                        sm: 12,
                        lg: 2,
                      },
                      {
                        type: 'text',
                        name: 'flightNumbers',
                        label: 'Número(s) de voo(s)',
                        min: 0,
                        sm: 12,
                        lg: 2,
                        placeholder: 'Separados por vírgula',
                      },
                      {
                        type: 'button',
                        onClick: () => searchButtonHandler(formAirData),
                        label: 'Pesquisar',
                        lg: 12,
                        style: { float: 'right' },
                      },
                      ...(firstSearchIsDone
                        ? [
                            {
                              type: 'tableToList',
                              label:
                                currentFlightList == 1
                                  ? 'Voos de ida'
                                  : 'Voos de volta',
                              name: 'flights',
                              sm: 12,
                              lg: 12,
                              nameButton:
                                fData?.type?.value == 'Ida e Volta'
                                  ? currentFlightList == 1
                                    ? 'Escolher Voo(s) de volta'
                                    : 'Escolher Voo(s) de ida'
                                  : null,
                              handle: () => {
                                setCurrentFlightList(
                                  currentFlightList == 1 ? 2 : 1,
                                )
                              },
                              columns: [
                                {
                                  Header: '',
                                  accessor: 'selectFlight',
                                  width: 20,
                                  Cell: props => {
                                    return (
                                      <input
                                        name={'selectFlight'}
                                        onChange={e =>
                                          handleCheckboxClick(e, props)
                                        }
                                        type='checkbox'
                                        checked={
                                          checkedState[props.row.original.id]
                                        }
                                      />
                                    )
                                  },
                                },
                                {
                                  Header: 'Cia Aérea',
                                  accessor: 'companyName',
                                },
                                {
                                  Header: 'Partida',
                                  accessor: 'departureDate',
                                  Cell: props => {
                                    if (props.row.original.departureDate) {
                                      return getDateFormated(
                                        props.row.original.departureDate,
                                        'DD/MM/YYYY HH:mm',
                                      )
                                    }

                                    return ''
                                  },
                                },
                                {
                                  Header: 'Chegada',
                                  accessor: 'arrivalDate',
                                  Cell: props => {
                                    if (props.row.original.arrivalDate) {
                                      return getDateFormated(
                                        props.row.original.arrivalDate,
                                        'DD/MM/YYYY HH:mm',
                                      )
                                    }

                                    return ''
                                  },
                                },
                                {
                                  Header: 'Paradas',
                                  accessor: 'numberOfStops',
                                },
                                {
                                  Header: 'Itinerário',
                                  accessor: 'routeResume',
                                },
                              ],
                            },
                          ]
                        : []),
                    ]}
                  />
                </CustomModalContent>
              )}
              {modaStage == '2' && excerptDetails && (
                <CustomModalContent>
                  <CustomModalContentHeader>
                    <div>
                      <h5>Trecho</h5>
                    </div>

                    <IconButton
                      type={'close'}
                      onClick={() => {
                        setDefaultValueExcerpt({})
                        setOpenModal(state => !state)
                        setExcerptId(0)
                        setModalStage('1')
                        setExcerptDetails(false)
                      }}
                    />
                  </CustomModalContentHeader>

                  <Divisor border />

                  <DefaultForm
                    defaultValue={defaultValueExcerpt}
                    loading={loadingExcerpt}
                    callbackSubmit={onSubmitExcerpt}
                    callbackReset={() => {}}
                    callbackCancel={() => {
                      setModalStage('1')
                      setExcerptDetails(false)
                    }}
                    cancelLabel={
                      excerptDetails ? 'Criar automaticamente' : 'Voltar'
                    }
                    inputs={[
                      {
                        type: 'text',
                        name: 'flight',
                        label: 'Voo',
                        placeholder: 'Ex: ABC123',
                        searchButton: false,
                        callbackSearch: onSearch,
                        sm: 12,
                        lg: 3,
                      },
                      {
                        type: 'text',
                        name: 'cia',
                        label: 'Cia aérea',
                        sm: 12,
                        lg: 7,
                      },
                      {
                        empty: true,
                        text: '<h6>Embarque</h6>',
                        sm: 12,
                      },
                      {
                        type: 'text',
                        name: 'iataOrigin',
                        label: 'IATA',
                        callbackSearch: onChangeIata,
                        searchButton: true,
                        required: true,
                        sm: 12,
                        lg: 1,
                      },
                      {
                        type: 'text',
                        name: 'airportOrigin',
                        label: 'Aeroporto',
                        required: true,
                        sm: 12,
                        lg: 4,
                      },
                      {
                        type: 'text',
                        name: 'origin',
                        label: 'Cidade',
                        sm: 12,
                        lg: 3,
                      },
                      {
                        type: 'date',
                        name: 'dateOrigin',
                        label: 'Data',
                        sm: 12,
                        lg: 2,
                      },
                      {
                        type: 'time',
                        name: 'timeOrigin',
                        label: 'Hora',
                        sm: 12,
                        lg: 2,
                      },
                      {
                        empty: true,
                        text: '<h6>Desembarque</h6>',
                        sm: 12,
                      },
                      {
                        type: 'text',
                        name: 'iataDestiny',
                        label: 'IATA',
                        callbackSearch: onChangeIata,
                        searchButton: true,
                        required: true,
                        sm: 12,
                        lg: 1,
                      },
                      {
                        type: 'text',
                        name: 'airportDestiny',
                        label: 'Aeroporto',
                        required: true,
                        sm: 12,
                        lg: 4,
                      },
                      {
                        type: 'text',
                        name: 'destiny',
                        label: 'Cidade',
                        sm: 12,
                        lg: 3,
                      },
                      {
                        type: 'date',
                        name: 'dateDestiny',
                        label: 'Data',
                        sm: 12,
                        lg: 2,
                        minDate: minDate,
                      },
                      {
                        type: 'time',
                        name: 'timeDestiny',
                        label: 'Hora',
                        sm: 12,
                        lg: 2,
                      },
                    ]}
                  />
                </CustomModalContent>
              )}
            </CustomModal>
          )}

          {flightId == 0 && (
            <b style={{ fontSize: '16px' }}>
              ** Para criar trechos para esse aéreo é necessário clicar em
              Salvar
            </b>
          )}
          {flightId > 0 && (
            <Panel>
              <PanelHeader noButton title={'Trechos'}>
                <Create
                  onClick={() => {
                    setFirstSearchIsDone(false)
                    flightData.current = []
                    setSelectedFlights([])
                    setCheckedState([])
                    setDefaultData(oldState => {
                      return {
                        ...oldState,
                        flights: [],
                      }
                    })
                    setOpenModal(state => !state)
                  }}
                >
                  Adicionar trecho
                </Create>
              </PanelHeader>

              <PanelBody>
                <ReactTable
                  data={dataExcerpts}
                  columns={[
                    {
                      Header: 'Tipo',
                      accessor: 'type',
                      width: 100,
                    },
                    {
                      Header: 'Voo',
                      accessor: 'flight',
                      width: 100,
                    },
                    {
                      Header: 'Cia',
                      accessor: 'cia',
                      width: 200,
                    },
                    {
                      Header: 'Origem',
                      accessor: 'origin',
                      width: 200,
                    },
                    {
                      Header: 'Destino',
                      accessor: 'destiny',
                      width: 200,
                    },
                    {
                      Header: '',
                      accessor: 'edit',
                      width: 60,
                      Cell: props => {
                        return (
                          <IconButton
                            tooltipOverride={'Detalhes'}
                            onClick={() => {
                              setOpenModal(state => !state)
                              getExcerpts(props.row.original.id)
                              setExcerptId(props.row.original.id)
                              setExcerptDetails(true)
                              setModalStage('2')
                            }}
                            type='details'
                          />
                        )
                      },
                    },
                    {
                      Header: '',
                      accessor: 'clone',
                      Cell: props => {
                        return (
                          <IconButton
                            id={props.row.original.id}
                            type={'clone'}
                            iconOverride={'far fa-clone'}
                            tooltipOverride={'Clonar'}
                            onClick={() =>
                              onClone('flight-excerpt', props.row.original.id)
                            }
                          />
                        )
                      },
                      width: 20,
                    },
                    {
                      Header: '',
                      accessor: 'id',
                      width: 60,
                      Cell: props => {
                        return (
                          <IconButton
                            tooltipOverride={'Remover trecho'}
                            onClick={() => onRemove(props.row.original.id)}
                            type='trash'
                          />
                        )
                      },
                    },
                  ]}
                  tableProps={{
                    defaultPageSize: 10,
                    hasFooter: false,
                    hasPagination: true,
                    loading: loading,
                    resizable: true,
                  }}
                  tableStyle={{
                    fontSize: '13px',
                    cellPadding: '6px 4px',
                  }}
                  tableLabels={{
                    loadingText: 'Carregando...',
                    noDataText: 'Sem registros',
                    nextButtonText: 'Próximo',
                    previousButtonText: 'Anterior',
                    pageOfText: 'de',
                    pageSizeSelectText: 'registros',
                    pageText: 'Página',
                  }}
                />
              </PanelBody>
            </Panel>
          )}

          <DefaultForm
            callbackSubmit={data => onSubmit(data, true, true)}
            callbackReset={() => {}}
            callbackCancel={data => onSubmit(data, true, false)}
            submitLabel='Salvar e fechar'
            cancelLabel='Salvar'
            defaultValue={defaultValue}
            disabled={loading}
            loading={loading}
            inputs={inputs}
            onChangeData={data => setFData(data)}
          />
        </Container>
      }
    />
  )
}

const Container = styled.div`
  position: relative;
`

const CustomModal = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.3);
  width: 100%;
  height: 100%;
  z-index: 9999;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`

const CustomModalContent = styled.div`
  background: white;
  width: 95%;
  padding: 8px;
  margin: auto auto;
  border-radius: 8px;
  box-shadow: 0px 0px 16px #333;
`

const CustomModalContentHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
`
