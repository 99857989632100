import DefaultForm from '~/components/DefaultForm'
import {
  Container,
  CoverHeader,
  Div,
  Footer,
  Header,
  ImageButton,
} from './style'
import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { useParams } from 'react-router-dom'
import { apiRequest } from '~/apiService'
import LoadingContainer from '~/components/LoadingContainer'
import { inputCheck, inputs, inputsToExpand } from './inputs'

export default function ShortForm() {
  const params = useParams()
  const expandedForm =
    window.location.pathname.split('/')[1] == 'request-form-short-e'
  const [image, setImage] = useState(null)
  const [loading, setLaoding] = useState(false)
  const [ddi, setDdi] = useState([{}])

  document.title = 'Plataforma CRM especializada em agências de viagens'

  useEffect(() => {
    getSource()
    getOptionsDDI()
  }, [])

  async function getSource() {
    const response = await apiRequest(
      'get',
      `/agent/token/${params.token}`,
      {},
      setLaoding,
    )

    if (response.error == false) setImage(response.data.image)
  }

  async function getOptionsDDI() {
    const response = await apiRequest('options', '/ddi')

    if (response.error == false) setDdi(response.data)
  }

  const requestApiPostToCreate = async data => {
    if (
      data.confirmTermOfService == undefined ||
      data.confirmTermOfService == false
    ) {
      toast.info(
        'Você precisar confirmar que leu e que está de acordo com as condições gerais.',
      )

      return
    }

    const body = {
      ...data,
      token: params.token,
      leadFormId: params.leadFormId,
      campaignId: params.campaignId,
      expandedForm: expandedForm,
    }

    const response = await apiRequest(
      'post',
      '/customer/insert-new-customer-from-short-form',
      body,
    )

    if (response.error == false) {
      toast.success('Cadastro realizado com sucesso!')
      setTimeout(() => window.location.reload(), 1000)
    }
  }

  return (
    <Div>
      {loading && <LoadingContainer />}
      {!loading && (
        <>
          <CoverHeader>
            <Header>
              <ImageButton>
                <img
                  src={`data:image/*;base64,${image}`}
                  alt='logo'
                  style={{ maxWidth: '150px' }}
                />
              </ImageButton>
            </Header>
          </CoverHeader>

          <Container>
            <h4>
              Obrigado pela oportunidade de lhe atender! <br /> Tenha a certeza
              que faremos de tudo ao nosso alcance para realizar seus sonhos
              nessa próxima viagem. Por favor informe os dados abaixo para
              continuarmos:
            </h4>

            <DefaultForm
              callbackSubmit={requestApiPostToCreate}
              submitLabel='Enviar'
              inputs={
                expandedForm == true
                  ? inputs(ddi).concat(inputsToExpand).concat(inputCheck)
                  : inputs(ddi).concat(inputCheck)
              }
            />
          </Container>

          <Footer>
            <img
              src={`data:image/*;base64,${image}`}
              alt='logo'
              style={{ maxWidth: '150px' }}
            />
          </Footer>
        </>
      )}
    </Div>
  )
}
